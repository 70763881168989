import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Typography,
  Button,
  IconButton,
  AppBar,
  Toolbar,
  ThemeProvider,
  Grid,
  Modal,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";
import theme from "../../../theme"; // Sesuaikan path dengan lokasi file theme.js
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 5,
};

const CategoryList = () => {
  const [categories, setCategories] = useState([]);
  const navigate = useNavigate();

  const [modalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const handleOpenModal = (message) => {
    setModalMessage(message);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const navigateToAddCategory = () => {
    navigate("/products/add-category");
  };

  const navigateToCategoryPage = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    // Mengambil data kategori dari server
    fetch("https://server.ajeg.co.id/api/categories")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setCategories(data);
      })
      .catch((error) => console.error("Terjadi kesalahan:", error));
  }, []);

  const handleEditCategory = (categoryId) => {
    // Navigasi ke halaman edit-category/:id
    navigate(`/products/edit-category/${categoryId}`);
  };

  const handleDeleteCategory = async (categoryId) => {
    try {
      const response = await fetch(
        `https://server.ajeg.co.id/api/categories/${categoryId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        handleOpenModal(`Kategori dengan ID ${categoryId} berhasil dihapus`);

        // Pembaruan tampilan di frontend
        // Menghapus kategori dari array categories
        setCategories((prevCategories) =>
          prevCategories.filter(
            (category) => category.id_kategori !== categoryId
          )
        );

        // Lakukan tindakan lanjutan jika diperlukan
      } else {
        handleOpenModal("Gagal menghapus kategori");
        // Handle kesalahan, mungkin menampilkan pesan kesalahan kepada pengguna
      }
    } catch (error) {
      handleOpenModal("Terjadi kesalahan:", error);
      // Handle kesalahan, misalnya menampilkan pesan kesalahan kepada pengguna
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          position="sticky"
          elevation={0}
          sx={{
            backgroundColor: theme.palette.background.appBar,
            color: "black",
          }}
        >
          <Toolbar>
            <Typography variant="h6" sx={{ flexGrow: 1, color: "black" }}>
              Daftar Kategori
            </Typography>
            <Button
              variant="contained"
              color="primary"
              component={Link}
              to="/products/add-category"
              sx={{
                textTransform: "none",
                fontWeight: 500,
                "&:hover": {
                  "& svg": {
                    transform: "rotate(45deg)", // Memiringkan ikon 45 derajat saat tombol dihover
                  },
                },
              }}
              startIcon={<AddIcon sx={{ transition: "transform 0.3s" }} />}
            >
              Tambahkan Kategori
            </Button>
          </Toolbar>
        </AppBar>
        <Grid sx={{ padding: 3 }}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead
                sx={{
                  backgroundColor: "#f5f5f5",
                }}
              >
                <TableRow>
                  <TableCell>No</TableCell>
                  <TableCell>Kategori</TableCell>
                  <TableCell>Subkategori</TableCell>
                  <TableCell>Aksi</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {categories.map((category, index) => (
                  <TableRow
                    sx={{
                      backgroundColor: "white",
                      "&:hover": {
                        backgroundColor: "#f5f5f5",
                      },
                    }}
                    key={category.id_kategori}
                  >
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{category.kategori}</TableCell>
                    <TableCell>
                      {category.subkategori
                        ? category.subkategori.join(", ")
                        : "-"}
                    </TableCell>
                    <TableCell>
                      <IconButton
                        onClick={() => handleEditCategory(category.id_kategori)}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        onClick={() =>
                          handleDeleteCategory(category.id_kategori)
                        }
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Modal
          open={modalOpen}
          onClose={handleCloseModal}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-title" variant="h6" component="h2">
              Notifikasi
            </Typography>
            <Typography id="modal-description" sx={{ mt: 2 }}>
              {modalMessage}
            </Typography>
            <Grid container spacing={2} sx={{ mt: 2 }}>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="outlined"
                  onClick={navigateToAddCategory}
                >
                  Tambahkan Kategori Baru
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={navigateToCategoryPage}
                >
                  Kembali
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      </Box>
    </ThemeProvider>
  );
};

export default CategoryList;
