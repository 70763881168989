// src/context/AuthContext.js
import React, { createContext, useState, useContext, useEffect } from "react";

// Membuat context
const AuthContext = createContext();

// Hook untuk menggunakan context
export const useAuth = () => useContext(AuthContext);

// Provider component
export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    console.log("Memulai inisialisasi user");

    const token = localStorage.getItem("authToken");
    console.log("Token saat ini:", token);

    const initializeUser = async () => {
      try {
        if (token) {
          const user = await fetchUserDetails(token);
          setCurrentUser(user);
          console.log("User diinisialisasi:", user);
        }
      } catch (error) {
        console.error("Failed to fetch user details:", error);
      } finally {
        setIsLoading(false);
      }
    };

    initializeUser();
  }, []);

  const login = async (email, password) => {
    try {
      const { token } = await loginUser(email, password);
      console.log("Token received:", token); // Log untuk memastikan token diterima
      localStorage.setItem("authToken", token);

      // Jika server tidak mengembalikan 'user', buat request tambahan
      const user = await fetchUserDetails(token);
      console.log("User fetched:", user); // Log untuk memastikan data user diterima

      setCurrentUser(user);
      console.log("CurrentUser set to:", user); // Log untuk memastikan currentUser diatur
    } catch (error) {
      console.error("Login failed:", error);
      throw new Error("Login failed. Please try again.");
    }
  };

  const logout = () => {
    localStorage.removeItem("authToken");
    setCurrentUser(null);
  };

  const register = async (username, email, password) => {
    try {
      const { user, token } = await registerUser(username, email, password);
      localStorage.setItem("authToken", token);
      setCurrentUser(user);
    } catch (error) {
      console.error("Registration failed:", error);
      throw new Error("Registration failed. Please try again.");
    }
  };

  const value = {
    currentUser,
    isLoading,
    login,
    logout,
    register,
  };

  console.log("CurrentUser:", currentUser);
  console.log("isLoading:", isLoading);

  return (
    <AuthContext.Provider value={value}>
      {!isLoading && children}
    </AuthContext.Provider>
  );
};

// Fungsi untuk mengirim request login
const loginUser = async (email, password) => {
  const response = await fetch("https://server.ajeg.co.id/api/login", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email, password }),
  });

  if (!response.ok) {
    throw new Error("Login failed. Invalid email or password.");
  }

  return await response.json();
};

// Fungsi untuk mengirim request registrasi
const registerUser = async (username, email, password) => {
  const response = await fetch("https://server.ajeg.co.id/api/register", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ username, email, password }),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || "Registration failed.");
  }

  return await response.json();
};

// Fungsi untuk mengambil detail user
const fetchUserDetails = async (token) => {
  try {
    const response = await fetch("https://server.ajeg.co.id/api/user", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error("Failed to fetch user details.");
    }

    const user = await response.json();
    return user;
  } catch (error) {
    console.error("Error fetching user details:", error);
    throw error;
  }
};

export default AuthContext; // Ekspor AuthContext sebagai default
