//EditProduct.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Paper,
  Typography,
  TextField,
  Grid,
  CircularProgress,
  Button,
  AppBar,
  Toolbar,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
} from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import SendIcon from "@mui/icons-material/Send"; // Impor SendIcon
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/system";

const EditProduct = () => {
  const { productId } = useParams();
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]); // State untuk kategori
  const [subcategories, setSubcategories] = useState([]); // State untuk subkategori
  const [vendors, setVendors] = useState([]); // State untuk vendor

  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [selectedSubcategoryId, setSelectedSubcategoryId] = useState("");
  const [selectedVendorId, setSelectedVendorId] = useState("");

  const [product, setProduct] = useState({
    // Inisialisasi nilai-nilai produk yang akan diubah
    nama: "",
    sku: "",
    kategori: "",
    subkategori: "",
    harga_jual: "",
    deskripsi: "",
    panjang: 0,
    lebar: 0,
    tinggi: 0,
    jenis_kain: "",
    jenis_dudukan: "",
    jenis_kaki: "",
    bantal_peluk: 0,
    bantal_sandaran: 0,
    puff: false,
    nama_style: "",
    nama_finishing: "",

    estimasi_waktu_produksi: 0, // Tambahkan field ini
    warrantyPeriod: 0, // Tambahkan field ini
  });
  const [loading, setLoading] = useState(true);
  const theme = useTheme();
  const [productImage, setProductImage] = useState("");
  const [newProductImage, setNewProductImage] = useState(null);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await axios.get(
          `https://server.ajeg.co.id/api/products/${productId}`
        );

        const productWithEstimation = {
          ...response.data,
          estimasi_waktu_produksi: response.data.estimasi_waktu_produksi,
          warrantyPeriod: response.data.masa_garansi, // Memetakan 'masa_garansi' dari server ke 'warrantyPeriod' di state
        };

        console.log("Produk yang sedang di-edit:", productWithEstimation);

        setProduct(productWithEstimation);
        setProductImage(response.data.foto_produk);

        const categoriesResponse = await axios.get(
          `https://server.ajeg.co.id/api/product/${productId}/categories`
        );
        console.log("Kategori:", categoriesResponse.data);
        setCategories(categoriesResponse.data);

        const subcategoriesResponse = await axios.get(
          `https://server.ajeg.co.id/api/product/${productId}/subcategories`
        );
        console.log("Subkategori:", subcategoriesResponse.data);
        setSubcategories(subcategoriesResponse.data);

        const vendorsResponse = await axios.get(
          `https://server.ajeg.co.id/api/product/${productId}/vendors`
        );
        console.log("Vendor:", vendorsResponse.data);
        setVendors(vendorsResponse.data);

        console.log("Kategori yang tersedia:", categories);
        console.log("Subkategori yang tersedia:", subcategories);
        console.log("Vendor yang tersedia:", vendors);

        // Set nilai default jika tidak ada kategori terpilih
        if (!selectedCategoryId && categories.length > 0) {
          setSelectedCategoryId(categories[0].id_kategori);
        }

        // Set nilai default jika tidak ada subkategori terpilih
        if (!selectedSubcategoryId && subcategories.length > 0) {
          setSelectedSubcategoryId(subcategories[0].id_subkategori);
        }

        // Set nilai default jika tidak ada vendor terpilih
        if (!selectedVendorId && vendors.length > 0) {
          setSelectedVendorId(vendors[0].id_vendor);
        }

        setLoading(false);
      } catch (error) {
        console.error("Error fetching product:", error);
        setLoading(false);
      }
    };

    fetchProduct();
  }, [productId]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "kategori") {
      setSelectedCategoryId(value);
    } else if (name === "subkategori") {
      setSelectedSubcategoryId(value);
    } else if (name === "nama_vendor") {
      setSelectedVendorId(value);
    }
    console.log("Nama field:", e.target.name);
    console.log("Nilai yang diubah:", e.target.value);
    setProduct({ ...product, [e.target.name]: e.target.value });
  };

  const handleDescriptionChange = (e) => {
    setProduct({ ...product, deskripsi: e.target.value });
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleSubmit = async () => {
    try {
      const formData = new FormData();
      formData.append("nama", product.nama);
      formData.append("sku", product.sku);
      formData.append("id_kategori", selectedCategoryId); // Perbarui dengan ID yang dipilih
      formData.append("id_subkategori", selectedSubcategoryId); // Perbarui dengan ID yang dipilih
      formData.append("id_vendor", selectedVendorId); // Perbarui dengan ID yang dipilih
      // ...
      if (newProductImage) {
        formData.append("foto_produk", newProductImage);

        // Setelah mengunggah gambar, perbarui state productImage
        setProductImage(URL.createObjectURL(newProductImage));
      }

      // Kirim formData ke server menggunakan axios.post atau metode serupa
      await axios.post(
        `https://server.ajeg.co.id/api/products/${productId}`,
        formData
      );

      // Tambahan logika atau navigasi setelah pengiriman berhasil
    } catch (error) {
      console.error("Error submitting product:", error);
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="sticky"
        elevation={0}
        sx={{
          backgroundColor: theme.palette.background.appBar,
          color: "black",
          marginBottom: 2,
        }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="back"
            onClick={handleBack}
            sx={{
              margin: 1,
            }}
          >
            <KeyboardBackspaceIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, color: "black" }}
          >
            Edit Produk
          </Typography>
          <Button
            sx={{
              paddingLeft: 2,
              paddingRight: 2,
              backgroundColor: theme.palette.primary.main,
              color: "white",
              "&:hover": {
                backgroundColor: theme.palette.primary.dark,
                "& svg": {
                  transform: "rotate(-45deg)",
                },
              },
              textTransform: "none",
              fontWeight: 500,
            }}
            startIcon={<SendIcon sx={{ transition: "transform 0.3s" }} />}
            onClick={handleSubmit}
          >
            Simpan Perubahan
          </Button>
        </Toolbar>
      </AppBar>

      <Grid
        container
        spacing={3}
        sx={{ paddingLeft: "20px", paddingRight: "20px" }}
      >
        <Grid item xs={6} md={4}>
          <Paper sx={{ padding: 2, marginBottom: 3 }}>
            <Typography variant="subtitle1" gutterBottom>
              Foto Produk
            </Typography>
            {productImage && (
              <img
                src={productImage}
                alt="Product"
                style={{
                  width: "100%",
                  maxHeight: "400px",
                  objectFit: "contain",
                }}
              />
            )}
            <input
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              id="fileInput"
              onChange={(e) => {
                setNewProductImage(e.target.files[0]);
                setProductImage(URL.createObjectURL(e.target.files[0]));
              }}
            />
            <label htmlFor="fileInput">
              <Button
                fullWidth
                component="span"
                variant="contained"
                color="primary"
                sx={{ marginTop: 2 }}
              >
                Ganti Foto
              </Button>
            </label>
          </Paper>

          <Paper sx={{ padding: 2, marginBottom: 3 }}>
            <Typography variant="subtitle1" gutterBottom>
              Spesifikasi Khusus
            </Typography>
          </Paper>
        </Grid>

        <Grid item xs={6} md={8}>
          <Paper variant="outlined" sx={{ p: 2 }}>
            <Typography variant="subtitle1" gutterBottom>
              Informasi Produk
            </Typography>
            <TextField
              fullWidth
              label="Nama Produk"
              name="nama"
              value={product.nama}
              onChange={handleChange}
              margin="normal"
            />
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl fullWidth margin="normal">
                  <InputLabel>Kategori</InputLabel>
                  <Select
                    name="kategori"
                    value={product.kategori}
                    onChange={handleChange}
                    label="Kategori"
                  >
                    {categories.map((category) => (
                      <MenuItem
                        key={category.id_kategori}
                        value={category.kategori}
                      >
                        {category.kategori}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth margin="normal">
                  <InputLabel>Subkategori</InputLabel>
                  <Select
                    name="subkategori"
                    value={product.subkategori}
                    onChange={handleChange}
                    label="Subkategori"
                  >
                    {subcategories.map((subcategory) => (
                      <MenuItem
                        key={subcategory.id_subkategori}
                        value={subcategory.subkategori}
                      >
                        {subcategory.subkategori}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <FormControl fullWidth margin="normal">
              <InputLabel>Vendor</InputLabel>
              <Select
                name="nama_vendor"
                value={product.nama_vendor}
                onChange={handleChange}
                label="Vendor"
              >
                {vendors.map((vendor) => (
                  <MenuItem key={vendor.id_vendor} value={vendor.nama_vendor}>
                    {vendor.nama_vendor}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              fullWidth
              label="SKU - dibuat secara otomatis"
              name="sku"
              value={product.sku}
              onChange={handleChange}
              margin="normal"
              InputProps={{
                readOnly: true,
              }}
            />
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Estimasi Waktu Produksi"
                  name="estimasi_waktu_produksi"
                  type="number"
                  value={product.estimasi_waktu_produksi}
                  onChange={handleChange}
                  margin="normal"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">Hari</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Masa Garansi (Tahun)"
                  name="warrantyPeriod"
                  type="number"
                  value={product.warrantyPeriod} // Pastikan ini diatur dengan benar
                  onChange={handleChange}
                  margin="normal"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">Tahun</InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>

            <Typography variant="subtitle1" marginTop={2}>
              Dimensi Produk
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label="Panjang"
                  name="panjang"
                  type="number"
                  value={product.panjang}
                  onChange={handleChange}
                  margin="normal"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">cm</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label="Lebar"
                  name="lebar"
                  type="number"
                  value={product.lebar}
                  onChange={handleChange}
                  margin="normal"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">cm</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label="Tinggi"
                  name="tinggi"
                  type="number"
                  value={product.tinggi}
                  onChange={handleChange}
                  margin="normal"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">cm</InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <TextField
              label="Deskripsi Produk"
              multiline
              rows={4}
              value={product.deskripsi}
              onChange={handleDescriptionChange}
              variant="outlined"
              fullWidth
              margin="normal"
            />
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Harga Jual"
                  name="harga_jual"
                  value={product.harga_jual}
                  onChange={handleChange}
                  margin="normal"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">Rp</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Harga Beli"
                  name="harga_beli"
                  value={product.harga_beli}
                  onChange={handleChange}
                  margin="normal"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">Rp</InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            {/* Tambahkan lebih banyak field sesuai kebutuhan */}
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default EditProduct;
