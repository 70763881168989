// ProductSidebar.js
import React, { useState, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  Divider,
} from "@mui/material";
import IconKain from "../../assets/icons/iconKain.svg";
import IconKategori from "../../assets/icons/iconKategori.svg";
import IconProduk from "../../assets/icons/iconProduk.svg";
import IconVendor from "../../assets/icons/iconVendor.svg";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import LogoutIcon from "@mui/icons-material/Logout"; // Import Logout Icon
import logo from "../../assets/logos/furnindo-icon.svg"; // Sesuaikan dengan path yang benar
import AuthContext from "../../context/AuthContext";

const ProductSidebar = () => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate(); // Hook untuk navigasi
  const { logout } = useContext(AuthContext); // Gunakan useContext untuk mengakses AuthContext

  // Fungsi handleLogout
  const handleLogout = () => {
    logout(); // Memanggil fungsi logout dari AuthContext
    navigate("/login"); // Mengarahkan pengguna ke halaman login
  };

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: isSidebarOpen ? 300 : 70,
        flexShrink: 0,
        borderRadius: "8px",
        transition: "width 0.3s",
        "& .MuiDrawer-paper": {
          width: isSidebarOpen ? 300 : 70,
          boxSizing: "border-box",
          transition: "width 0.3s",
        },
      }}
    >
      <ListItem sx={{ marginTop: 1, marginBottom: 1.5 }}>
        <ListItemIcon>
          <img src={logo} alt="Logo" style={{ width: 30, height: 30 }} />
        </ListItemIcon>
        {isSidebarOpen && (
          <Typography variant="title" sx={{ fontWeight: 600 }}>
            Furnindo Tech
          </Typography>
        )}
      </ListItem>
      <Divider />

      <List>
        <ListItem
          component={Link}
          to="/products"
          button
          selected={location.pathname === "/products"}
        >
          <ListItemIcon>
            <img
              src={IconProduk}
              alt="Icon Produk"
              style={{ width: 30, height: 30 }}
            />
          </ListItemIcon>
          {isSidebarOpen && (
            <Typography variant="body1" style={{ fontWeight: 500 }}>
              Produk
            </Typography>
          )}
        </ListItem>
        <ListItem
          component={Link}
          to="/products/vendor"
          button
          selected={location.pathname === "/products/vendor"}
        >
          <ListItemIcon>
            <img
              src={IconVendor}
              alt="Icon Vendor"
              style={{ width: 30, height: 30 }}
            />
          </ListItemIcon>
          {isSidebarOpen && (
            <Typography variant="body1" style={{ fontWeight: 500 }}>
              Vendor
            </Typography>
          )}
        </ListItem>

        <ListItem
          component={Link}
          to="/products/category"
          button
          selected={location.pathname === "/products/category"}
        >
          <ListItemIcon>
            <img
              src={IconKategori}
              alt="Icon Kategori"
              style={{ width: 30, height: 30 }}
            />
          </ListItemIcon>
          {isSidebarOpen && (
            <Typography variant="body1" style={{ fontWeight: 500 }}>
              Kategori
            </Typography>
          )}
        </ListItem>

        <ListItem
          component={Link}
          to="/products/fabric"
          button
          selected={location.pathname === "/products/fabric"}
        >
          <ListItemIcon>
            <img
              src={IconKain}
              alt="Icon Kain"
              style={{ width: 30, height: 30 }}
            />
          </ListItemIcon>
          {isSidebarOpen && (
            <Typography variant="body1" style={{ fontWeight: 500 }}>
              Kain
            </Typography>
          )}
        </ListItem>
      </List>
      {/* Bagian bawah */}
      <List sx={{ position: "absolute", bottom: 0, width: "100%" }}>
        <ListItem>
          <ListItemIcon onClick={toggleSidebar}>
            {isSidebarOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </ListItemIcon>
        </ListItem>
        <ListItem button onClick={handleLogout}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          {isSidebarOpen && <Typography variant="body1">Logout</Typography>}
        </ListItem>
      </List>
    </Drawer>
  );
};

export default ProductSidebar;
