// src/components/inventory/InventoryList.js

import React, { useState, useEffect } from "react";
import axios from "axios";
import InventoryCard from "./InventoryCard";
import {
  Grid,
  AppBar,
  Toolbar,
  Typography,
  Button,
  Tabs,
  Tab,
  TextField,
  InputAdornment,
  Modal,
  Box,
} from "@mui/material";
import theme from "../../theme"; // Pastikan ini sesuai dengan lokasi file tema Anda
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import AddStockForm from "./AddStockForm";
import StockOutForm from "./StockOutForm";

const InventoryList = () => {
  const [products, setProducts] = useState([]); // Menggunakan data produk
  const [categories, setCategories] = useState([]); // Data kategori
  const [activeTab, setActiveTab] = useState(0);
  const [searchQuery, setSearchQuery] = useState(""); // Query pencarian
  const navigate = useNavigate();

  const [openAddStock, setOpenAddStock] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null); // State untuk produk yang dipilih
  const [openStockOut, setOpenStockOut] = useState(false);
  const [selectedStockOutProduct, setSelectedStockOutProduct] = useState(null);

  const handleOpenAddStock = (productId, product) => {
    console.log("ID Produk yang dipilih untuk tambah stok:", product);
    axios
      .get(`https://server.ajeg.co.id/api/inventory/${productId}`)
      .then((response) => {
        const detailedProduct = response.data;
        console.log("Detail produk:", detailedProduct);
        setSelectedProduct(detailedProduct);
        setOpenAddStock(true);
      })
      .catch((error) => {
        console.error("Error fetching product details:", error);
        // Handle error, misalnya dengan menampilkan pesan kesalahan
      });
  };

  const handleOpenStockOut = (productId, product) => {
    axios
      .get(`https://server.ajeg.co.id/api/inventory/${productId}`)
      .then((response) => {
        const detailedProduct = response.data;
        setSelectedStockOutProduct(detailedProduct);
        setOpenStockOut(true);
      })
      .catch((error) => {
        console.error("Error fetching product details:", error);
      });
  };

  const handleCloseStockOut = () => {
    setOpenStockOut(false);
  };

  const handleCloseAddStock = () => {
    setOpenAddStock(false);
  };
  useEffect(() => {
    // Mengambil data kategori dari server
    axios
      .get("https://server.ajeg.co.id/api/categories")
      .then((response) => {
        setCategories(response.data);
      })
      .catch((error) => {
        console.error("Terjadi kesalahan saat mengambil data kategori:", error);
      });

    // Mengambil data produk dari server
    axios
      .get("https://server.ajeg.co.id/api/products")
      .then((response) => {
        setProducts(response.data);
      })
      .catch((error) => {
        console.error("Terjadi kesalahan saat mengambil data produk:", error);
      });
  }, []);

  const handleCardClick = (productId) => {
    navigate(`/products/${productId}`);
  };

  const handleViewStock = (productId) => {
    navigate(`${productId}`);
  };

  const handleChangeTab = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  return (
    <div>
      <AppBar
        position="sticky"
        elevation={0}
        sx={{
          backgroundColor: theme.palette.background.appBar,
          color: "black",
        }}
      >
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1, color: "black" }}>
            Data Inventaris
          </Typography>
          <TextField
            variant="outlined"
            placeholder="Cari Inventaris..."
            value={searchQuery}
            onChange={handleSearchChange}
            size="small"
            sx={{
              mr: 2,
              width: "270px",
              "& fieldset": {
                paddingLeft: (theme) => theme.spacing(2.5),
                borderRadius: "30px",
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => navigate("/inventory/add-item")}
          >
            Tambah Inventaris
          </Button>
        </Toolbar>
      </AppBar>

      <Tabs
        value={activeTab}
        onChange={handleChangeTab}
        sx={{ backgroundColor: theme.palette.background.paper, padding: 3 }}
      >
        <Tab
          label="Semua Item"
          sx={{
            color: activeTab === 0 ? "inherit" : "black",
            textTransform: "none",
          }}
        />
        {categories.map((category, index) => (
          <Tab
            key={index}
            label={category.kategori}
            sx={{
              color: activeTab === index + 1 ? "inherit" : "black",
              textTransform: "none",
            }}
          />
        ))}
      </Tabs>

      <Modal
        open={openAddStock}
        onClose={handleCloseAddStock}
        aria-labelledby="modal-add-stock"
        aria-describedby="modal-form-to-add-stock"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography
            id="modal-add-stock"
            variant="h6"
            component="h2"
            sx={{
              marginBottom: 3,
              textAlign: "center",
            }}
          >
            Tambahkan Stok
          </Typography>
          <AddStockForm
            selectedProduct={selectedProduct}
            onClose={handleCloseAddStock}
          />
        </Box>
      </Modal>

      <Modal
        open={openStockOut}
        onClose={handleCloseStockOut}
        aria-labelledby="modal-stock-out"
        aria-describedby="modal-form-stock-out"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography
            id="modal-stock-out"
            variant="h6"
            component="h2"
            sx={{
              marginBottom: 3,
              textAlign: "center",
            }}
          >
            Stok Keluar
          </Typography>
          <StockOutForm
            selectedProduct={selectedStockOutProduct}
            onClose={handleCloseStockOut}
          />
        </Box>
      </Modal>

      <Grid container spacing={3} sx={{ padding: 3 }}>
        {products
          .filter(
            (product) =>
              (activeTab === 0 ||
                product.kategori === categories[activeTab - 1].kategori) &&
              product.nama.toLowerCase().includes(searchQuery.toLowerCase())
          )
          .map((product) => (
            <Grid
              item
              key={product.id_produk}
              xs={12}
              sm={6}
              md={5}
              lg={3}
              xl={2}
            >
              {console.log("Kategori:", product.kategori)}
              {console.log("Subkategori:", product.subkategori)}
              <InventoryCard
                productId={product.id_produk}
                productName={product.nama}
                category={product.kategori}
                subcategory={product.subkategori}
                price={product.harga_jual}
                imageUrl={product.foto_produk}
                onAddStock={handleOpenAddStock}
                onStockOut={handleOpenStockOut}
                onViewStock={() => handleViewStock(product.id_produk)}
              />
            </Grid>
          ))}
      </Grid>
    </div>
  );
};

export default InventoryList;
