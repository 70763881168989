import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
  Grid,
  AppBar, // Tambahkan import untuk AppBar
  Toolbar, // Tambahkan import untuk Toolbar
  Modal,
  Box,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import theme from "../../../theme"; // Sesuaikan path sesuai struktur proyek Anda

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 5,
};

const VendorList = () => {
  const [vendors, setVendors] = useState([]);
  const navigate = useNavigate();

  const [modalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const handleOpenModal = (message) => {
    setModalMessage(message);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const navigateToAddVendor = () => {
    navigate("/products/add-vendor");
  };

  const navigateToVendorPage = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    fetch("https://server.ajeg.co.id/api/vendors")
      .then((response) => response.json())
      .then((data) => setVendors(data))
      .catch((error) => console.error("Terjadi kesalahan:", error));
  }, []);

  const handleEdit = (vendorId) => {
    navigate(`/products/edit-vendor/${vendorId}`);
  };

  const handleDelete = async (vendorId) => {
    try {
      if (!vendorId) {
        console.error("ID vendor tidak valid.");
        return;
      }

      console.log("Menghapus data vendor dengan ID:", vendorId);

      const response = await fetch(
        `https://server.ajeg.co.id/api/vendors/${vendorId}`,
        {
          method: "DELETE",
        }
      );

      if (response.ok) {
        handleOpenModal("Data vendor berhasil dihapus.");
        setVendors((prevVendors) =>
          prevVendors.filter((vendor) => vendor.id_vendor !== vendorId)
        );
      } else {
        handleOpenModal("Gagal menghapus data vendor.");
      }
    } catch (error) {
      handleOpenModal("Terjadi kesalahan:", error);
    }
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="sticky"
        elevation={0} // Menghilangkan shadow
        sx={{
          backgroundColor: theme.palette.background.appBar,
          color: "black", // Warna teks hitam
        }}
      >
        <Toolbar>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h6" sx={{ flexGrow: 1, color: "black" }}>
                Daftar Vendor
              </Typography>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                component={Link}
                to="/products/add-vendor"
                sx={{
                  textTransform: "none",
                  fontWeight: 500,
                  "&:hover": {
                    "& svg": {
                      transform: "rotate(45deg)", // Memiringkan ikon 45 derajat saat tombol dihover
                    },
                  },
                }}
                startIcon={<AddIcon sx={{ transition: "transform 0.3s" }} />}
              >
                Tambahkan Vendor
              </Button>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Grid sx={{ padding: 3 }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead sx={{ backgroundColor: "#f5f5f5" }}>
              <TableRow>
                <TableCell>Logo</TableCell>
                <TableCell>Nama Vendor</TableCell>
                <TableCell>Telepon</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Alamat</TableCell>
                <TableCell>Aksi</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {vendors.map((vendor) => (
                <TableRow
                  sx={{
                    backgroundColor: "white",
                    "&:hover": {
                      backgroundColor: "#f5f5f5",
                    },
                  }}
                  key={vendor.id_vendor}
                >
                  <TableCell>
                    <img
                      src={`${vendor.url_logo}`}
                      alt="Logo"
                      width="50"
                      height="100%"
                    />
                  </TableCell>
                  <TableCell>{vendor.nama_vendor}</TableCell>
                  <TableCell>{vendor.telepon}</TableCell>
                  <TableCell>{vendor.email}</TableCell>
                  <TableCell>{vendor.alamat}</TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => handleEdit(vendor.id_vendor)}
                      aria-label="edit"
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => handleDelete(vendor.id_vendor)}
                      aria-label="delete"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-title" variant="h6" component="h2">
            Notifikasi
          </Typography>
          <Typography id="modal-description" sx={{ mt: 2 }}>
            {modalMessage}
          </Typography>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="outlined"
                onClick={navigateToAddVendor}
              >
                Tambahkan Vendor Baru
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                onClick={navigateToVendorPage}
              >
                Kembali
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </Box>
  );
};

export default VendorList;
