// src/components/inventory/ViewStock.js

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import {
  Box,
  Typography,
  Grid,
  Paper,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  IconButton,
  useTheme,
  AppBar,
  Toolbar,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

const ViewStock = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { productId } = useParams();
  const [product, setProduct] = useState(null);
  const [stockInfo, setStockInfo] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProductAndStock = async () => {
      setLoading(true);
      try {
        const productResponse = await axios.get(
          `https://server.ajeg.co.id/api/products/${productId}`
        );
        setProduct(productResponse.data);

        const stockResponse = await axios.get(
          `https://server.ajeg.co.id/api/stock/${productId}`
        );
        setStockInfo(stockResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProductAndStock();
  }, [productId]);

  const [qrCodeData, setQrCodeData] = useState(null);

  const fetchQrCodeData = async () => {
    try {
      const response = await axios.get(
        `https://server.ajeg.co.id/api/qrcode/${productId}`
      );

      // Set QR Code data ke state
      setQrCodeData(response.data.qrcode);
    } catch (error) {
      console.error("Error fetching QR Code data:", error);
    }
  };

  // Panggil fungsi fetchQrCodeData setelah komponen dimuat
  useEffect(() => {
    fetchQrCodeData();
  }, [productId]);

  const handleBack = () => {
    navigate(-1);
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "80vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!product) {
    return (
      <Typography variant="body1" sx={{ textAlign: "center", mt: 4 }}>
        Produk tidak ditemukan.
      </Typography>
    );
  }

  return (
    <Box>
      <AppBar
        position="sticky"
        elevation={0}
        sx={{
          backgroundColor: theme.palette.background.appBar,
          color: "black",
        }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="back"
            onClick={handleBack}
            sx={{
              margin: 1,
            }}
          >
            <KeyboardBackspaceIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, color: "black" }}
          >
            View Stock
          </Typography>
        </Toolbar>
      </AppBar>

      <Box sx={{ padding: 3 }}>
        <Grid container spacing={1} sx={{ marginBottom: 2 }}>
          <Grid item xs={12} md={6}>
            {/* Tambahkan background dan padding untuk area gambar */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={product.foto_produk} // Pastikan properti ini sesuai dengan respons API Anda
                alt={product.nama}
                style={{
                  maxWidth: "100%",
                  maxHeight: "600px",
                  borderRadius: "16px",
                }} // Atur ukuran maksimum gambar
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper
              elevation={0}
              sx={{
                padding: 2,
                minHeight: "500px",
                borderRadius: "16px",
                overflow: "hidden",
              }}
            >
              {/* Atur minHeight agar sesuai dengan tinggi area gambar */}
              <Typography variant="subtitle1">
                {`${product.kategori} | ${product.subkategori}`}
              </Typography>

              <Typography variant={isMobile ? "h5" : "h4"} fontWeight={"600"}>
                {product.nama}
              </Typography>

              <Typography variant="body1" fontWeight={"500"} gutterBottom>
                {`SKU: ${product.sku}`}
              </Typography>
              <Typography
                variant="h5"
                fontWeight={"500"}
                color="primary"
                gutterBottom
              >
                {`Rp. ${Number(product.harga_jual).toLocaleString("id-ID")}`}
              </Typography>

              <Typography variant="body1" color="text.secondary" gutterBottom>
                {`Vendor:`}
              </Typography>
              {product.logo_vendor && (
                <img
                  src={product.logo_vendor}
                  alt="Vendor Logo"
                  style={{
                    width: "100px", // Sesuaikan ukuran logo vendor
                    height: "100%", // Sesuaikan ukuran logo vendor
                  }}
                />
              )}
              <Typography
                variant="subtitle1"
                fontWeight={"500"}
                gutterBottom
                sx={{ marginTop: 2 }}
              >
                Dimensi Ukuran:
              </Typography>

              {/* Informasi Panjang, Lebar, dan Tinggi */}
              <Grid container sx={{ marginBottom: 3 }}>
                <Grid item xs={4}>
                  <Typography variant="body1" gutterBottom>
                    Panjang
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body1" gutterBottom>
                    {`: ${product.panjang} cm`}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body1" gutterBottom>
                    Lebar
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body1" gutterBottom>
                    {`: ${product.lebar} cm`}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body1" gutterBottom>
                    Tinggi
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body1" gutterBottom>
                    {`: ${product.tinggi} cm`}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    variant="body1"
                    gutterBottom
                    sx={{ fontWeight: 500 }}
                  >
                    Masa Garansi
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography
                    variant="body1"
                    gutterBottom
                    sx={{ fontWeight: 500 }}
                  >
                    {`: ${product.masa_garansi} Tahun`}
                  </Typography>
                </Grid>
              </Grid>
              <Typography variant="body1" gutterBottom sx={{ fontWeight: 500 }}>
                Deskripsi Produk:{" "}
              </Typography>
              <Typography
                variant="body1"
                gutterBottom
                sx={{
                  border: "1px solid #ced4da",
                  borderRadius: "8px",
                  padding: "8px",
                  minHeight: "64px", // Ganti dengan tinggi minimal yang Anda inginkan
                  overflowY: "auto", // Aktifkan overflow jika ingin baris teks yang lebih banyak dari tinggi minimal yang ditetapkan
                }}
              >
                {product.deskripsi}
              </Typography>
            </Paper>
          </Grid>
        </Grid>

        <Grid container>
          {/* Grid Kiri Menapilkan Spesifikasi Sofa & Kagumi */}
          <Grid
            item
            xs={12}
            md={6}
            sx={{ padding: 1 }}
            order={product.kategori === "Sofa" ? 0 : 1}
          >
            {/* Tampilkan spesifikasi khusus untuk Sofa jika ada */}
            {product.kategori === "Sofa" && (
              <Paper
                sx={{
                  width: "100%",
                  height: "100%",
                  padding: 2,
                  marginTop: 2,
                  borderRadius: "16px",
                }}
              >
                <Typography variant="h6" gutterBottom>
                  Spesifikasi Khusus Sofa
                </Typography>
                <Grid container>
                  {/* Style Sofa */}
                  <Grid item xs={4}>
                    <Typography variant="body1" gutterBottom>
                      Style
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="body1" gutterBottom>
                      : {product.nama_style || "-"}
                    </Typography>
                  </Grid>

                  {/* Jenis Kain */}
                  <Grid item xs={4}>
                    <Typography variant="body1" gutterBottom>
                      Jenis Kain
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="body1" gutterBottom>
                      : {product.jenis_kain || "-"}
                    </Typography>
                  </Grid>

                  {/* Dudukan */}
                  <Grid item xs={4}>
                    <Typography variant="body1" gutterBottom>
                      Dudukan
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="body1" gutterBottom>
                      : {product.jenis_dudukan || "-"}
                    </Typography>
                  </Grid>

                  {/* Jenis Kaki */}
                  <Grid item xs={4}>
                    <Typography variant="body1" gutterBottom>
                      Jenis Kaki
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="body1" gutterBottom>
                      : {product.jenis_kaki || "-"}
                    </Typography>
                  </Grid>

                  <Grid item xs={4}>
                    <Typography variant="body1" gutterBottom>
                      Jumlah Bantal Peluk
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="body1" gutterBottom>
                      : {product.bantal_peluk || "-"}
                    </Typography>
                  </Grid>

                  {/* Jumlah Bantal Sandaran */}
                  <Grid item xs={4}>
                    <Typography variant="body1" gutterBottom>
                      Jumlah Bantal Sandaran
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="body1" gutterBottom>
                      : {product.bantal_sandaran || "-"}
                    </Typography>
                  </Grid>

                  {/* Termasuk Puff */}
                  <Grid item xs={4}>
                    <Typography variant="body1" gutterBottom>
                      Termasuk Puff
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="body1" gutterBottom>
                      : {product.puff ? "Ya" : "Tidak"}
                    </Typography>
                  </Grid>

                  {/* Tambahkan informasi lain seperti Bantal Peluk, Bantal Sandaran, dll., jika ada */}
                  {/* ... */}
                </Grid>
              </Paper>
            )}
          </Grid>

          {/* Grid Kanan Menapilkan QR Code */}
          <Grid
            item
            xs={12}
            md={6}
            sx={{ padding: 1 }}
            order={product.kategori === "Sofa" ? 1 : 0}
          >
            <Paper
              sx={{
                width: "100%",
                height: "100%",
                padding: 2,
                marginTop: 2,
                borderRadius: "16px",
              }}
            >
              <Typography variant="h6" gutterBottom>
                QR Code
              </Typography>
              {qrCodeData && (
                <img
                  src={qrCodeData} // Gunakan data QR Code dari state
                  alt="QR Code"
                  style={{
                    maxWidth: "550px",
                    maxHeight: "100%",
                    borderRadius: "8px",
                  }}
                />
              )}
              <Typography
                variant="body2"
                color="textSecondary"
                style={{ marginTop: "8px" }}
              >
                SKU: {product.sku}{" "}
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ padding: 3 }}>
        {/* Tabel Stok */}
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="stock table">
            <TableHead>
              <TableRow>
                <TableCell>SKU</TableCell>
                <TableCell>Warna</TableCell>
                <TableCell>Finishing</TableCell>
                <TableCell>Jumlah</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {stockInfo.map((stock, index) => (
                <TableRow key={index}>
                  <TableCell>{stock.sku}</TableCell>
                  <TableCell>{stock.warna || "N/A"}</TableCell>
                  <TableCell>{stock.finishing || "N/A"}</TableCell>
                  <TableCell>{stock.jumlah}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default ViewStock;
