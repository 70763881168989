// src/components/inventory/AddStockForm.js

import React, { useState, useEffect } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Box,
  Typography,
  TextField, // Import TextField untuk input jumlah stok masuk
} from "@mui/material";
import axios from "axios";

const AddStockForm = ({ selectedProduct, onClose }) => {
  const [warnas, setWarnas] = useState([]);
  const [selectedWarna, setSelectedWarna] = useState("");
  const [jumlahStokMasuk, setJumlahStokMasuk] = useState(0); // State untuk jumlah stok masuk
  const [selectedFinishing, setSelectedFinishing] = useState("");
  const [finishingOptions, setFinishingOptions] = useState([]);
  const [skuAkhir, setSkuAkhir] = useState("");

  // State untuk menentukan apakah produk adalah sofa
  const [isSofa, setIsSofa] = useState(false);

  // State untuk menentukan apakah produkvendor  adalah Kagumi
  const [isKagumiVendor, setIsKagumiVendor] = useState(false);

  const [kategori, setKategori] = useState({});
  const [vendors, setVendors] = useState({});

  useEffect(() => {
    // Fetch kategori dan vendor dari server
    axios
      .get("https://server.ajeg.co.id/api/inventory/kategori")
      .then((response) => {
        const kategoriData = {};
        response.data.forEach((kat) => {
          kategoriData[kat.id_kategori] = kat.kategori;
        });
        setKategori(kategoriData);
      })
      .catch((error) => {
        console.error("Error fetching kategori:", error);
      });

    axios
      .get("https://server.ajeg.co.id/api/inventory/vendor")
      .then((response) => {
        const vendorData = {};
        response.data.forEach((ven) => {
          vendorData[ven.id_vendor] = ven.nama_vendor;
        });
        setVendors(vendorData);
      })
      .catch((error) => {
        console.error("Error fetching vendor:", error);
      });
  }, []);

  useEffect(() => {
    if (
      selectedProduct &&
      Object.keys(kategori).length &&
      Object.keys(vendors).length
    ) {
      const kategoriProduk = kategori[selectedProduct.id_kategori];
      const vendorProduk = vendors[selectedProduct.id_vendor];

      setIsSofa(kategoriProduk === "Sofa");
      setIsKagumiVendor(vendorProduk === "Kagumi");

      // Fetch warna jika produk adalah sofa
      if (kategoriProduk === "Sofa") {
        axios
          .get(
            `https://server.ajeg.co.id/api/inventory/warna/${selectedProduct.id_kain}`
          )
          .then((response) => setWarnas(response.data))
          .catch((error) => console.error("Error fetching warna data:", error));
      }

      // Fetch finishing jika produk adalah sofa atau vendor adalah Kagumi
      if (kategoriProduk === "Sofa" || vendorProduk === "Kagumi") {
        axios
          .get(`https://server.ajeg.co.id/api/inventory/finishing`)
          .then((response) => setFinishingOptions(response.data))
          .catch((error) =>
            console.error("Error fetching finishing data:", error)
          );
      }
    }
  }, [selectedProduct, kategori, vendors]);

  useEffect(() => {
    if (selectedProduct) {
      setSkuAkhir(generateSkuAkhir());
    }
  }, [
    selectedProduct,
    selectedWarna,
    selectedFinishing,
    isSofa,
    isKagumiVendor,
  ]);

  const generateSkuAkhir = () => {
    let skuAwal =
      selectedProduct && selectedProduct.sku ? selectedProduct.sku : "";
    let varianWarna = "NA";
    let varianFinishing = "NA";

    if (isSofa) {
      const warnaObj = warnas.find((w) => w.id_warna === selectedWarna);
      if (warnaObj && warnaObj.warna) {
        varianWarna = warnaObj.warna.substring(0, 3).toUpperCase();
      }

      const finishingObj = finishingOptions.find(
        (f) => f.id_finishing === selectedFinishing
      );
      if (finishingObj && finishingObj.finishing) {
        varianFinishing = finishingObj.finishing.substring(0, 3).toUpperCase();
      }
    } else if (isKagumiVendor) {
      varianWarna = "FNS"; // Placeholder untuk produk Kagumi
      const finishingObj = finishingOptions.find(
        (f) => f.id_finishing === selectedFinishing
      );
      if (finishingObj && finishingObj.finishing) {
        varianFinishing = finishingObj.finishing.substring(0, 3).toUpperCase();
      }
    }

    let varian = `${varianWarna}-${varianFinishing}`;
    return varian !== "NA-NA" ? `${skuAwal}-${varian}` : `${skuAwal}-NA-NA`;
  };

  const handleWarnaChange = (event) => {
    setSelectedWarna(event.target.value);
  };
  const handleJumlahStokChange = (event) => {
    setJumlahStokMasuk(event.target.value);
  };
  const handleFinishingChange = (event) => {
    setSelectedFinishing(event.target.value);
  };
  // Fungsi untuk menghandle perubahan pada field SKU Akhir
  const handleSkuAkhirChange = (event) => {
    setSkuAkhir(event.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let dataStokMasuk = {
      id_produk: selectedProduct.id_produk,
      jumlah_stok_masuk: jumlahStokMasuk,
      sku_akhir: skuAkhir,
    };

    // Tambahkan id_warna ke dataStokMasuk jika produk adalah sofa
    if (isSofa && selectedWarna) {
      dataStokMasuk.id_warna = selectedWarna;
    }

    // Tambahkan id_finishing ke dataStokMasuk jika produk adalah sofa atau vendor Kagumi
    if ((isSofa || isKagumiVendor) && selectedFinishing) {
      dataStokMasuk.id_finishing = selectedFinishing;
    }

    // Mengirim data ke server
    axios
      .post("https://server.ajeg.co.id/api/inventory/stokmasuk", dataStokMasuk)
      .then((response) => {
        console.log("Data berhasil disimpan:", response.data);
        onClose(); // Tutup form setelah submit berhasil
      })
      .catch((error) => {
        console.error("Error saat menyimpan data:", error);
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box display="flex" flexDirection="column" gap={2}>
        {/* Kondisional Rendering untuk Warna dan Finishing Kaki */}
        {isSofa && (
          <>
            <Typography>Kain: {selectedProduct?.kain}</Typography>
            <FormControl fullWidth>
              <InputLabel>Warna</InputLabel>
              <Select
                value={selectedWarna}
                label="Warna"
                onChange={handleWarnaChange}
              >
                {warnas.map((warna) => (
                  <MenuItem key={warna.id_warna} value={warna.id_warna}>
                    {warna.warna}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {selectedProduct.jenis_kaki === "Skandinavian" ||
            selectedProduct.jenis_kaki === "Balok Kayu" ? (
              <FormControl fullWidth>
                <InputLabel>Jenis Finishing Kaki</InputLabel>
                <Select
                  value={selectedFinishing}
                  label="Jenis Finishing Kaki"
                  onChange={handleFinishingChange}
                >
                  {finishingOptions.map((finishing) => (
                    <MenuItem
                      key={finishing.id_finishing}
                      value={finishing.id_finishing}
                    >
                      {finishing.finishing}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : null}
          </>
        )}

        {isKagumiVendor && (
          <FormControl fullWidth>
            <InputLabel>Finishing</InputLabel>
            <Select
              value={selectedFinishing}
              label="Finishing"
              onChange={handleFinishingChange}
            >
              {finishingOptions.map((finishing) => (
                <MenuItem
                  key={finishing.id_finishing}
                  value={finishing.id_finishing}
                >
                  {finishing.finishing}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        <TextField
          label="Jumlah Stok Masuk"
          type="number"
          value={jumlahStokMasuk}
          onChange={handleJumlahStokChange}
          fullWidth
        />
        <TextField
          label="SKU Akhir - dibuat secara otomatis"
          disabled
          value={skuAkhir}
          fullWidth
        />

        <Button type="submit" variant="contained" color="primary">
          Submit
        </Button>
      </Box>
    </form>
  );
};

export default AddStockForm;
