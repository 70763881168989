//ProductModule.js
import React from "react";
import { Routes, Route } from "react-router-dom";
import ProductSidebar from "./ProductSidebar";
import VendorPage from "./Vendor/VendorList";
import AddVendor from "./Vendor/AddVendor";
import EditVendor from "./Vendor/EditVendor";
import CategoryPage from "./Kategori/CategoryList";
import AddCategory from "./Kategori/AddCategory";
import EditCategory from "./Kategori/EditCategory";
import FabricPage from "./Kain/FabricList";
import EditFabric from "./Kain/EditFabric";
import AddProduct from "./AddProducts";
import EditProduct from "./EditProduct";
import AddFabric from "./Kain/AddFabric";
import ProductList from "./ProductList";
import SingleProduct from "./SingleProduct";
// Import other necessary components

const ProductModule = () => {
  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <ProductSidebar />
      <div style={{ flexGrow: 1 }}>
        {" "}
        {/* Adjust padding as needed */}
        <Routes>
          <Route path="/" element={<ProductList />} />
          <Route path="vendor" element={<VendorPage />} />
          <Route path="add-vendor" element={<AddVendor />} />
          <Route path="category" element={<CategoryPage />} />
          <Route path="edit-vendor/:vendorId" element={<EditVendor />} />
          <Route path="add-category" element={<AddCategory />} />
          <Route path="edit-category/:id" element={<EditCategory />} />
          <Route path="fabric" element={<FabricPage />} />
          <Route path="add-fabric" element={<AddFabric />} />
          <Route path="edit-fabric/:fabricId" element={<EditFabric />} />
          <Route path="add-product" element={<AddProduct />} />
          <Route path="/:productId" element={<SingleProduct />} />
          <Route path="/editproduct/:productId" element={<EditProduct />} />

          {/* Define more product-related routes here */}
          {/* Optional default page */}
        </Routes>
      </div>
    </div>
  );
};

export default ProductModule;
