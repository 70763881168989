// src/components/inventory/InventoryCard.js

import React from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  CardActions,
  Button,
} from "@mui/material";

const InventoryCard = ({
  productName,
  productId,
  category,
  subcategory,
  stock,
  imageUrl,
  onAddStock,
  onStockOut,
  onViewStock,
}) => {
  return (
    <Card style={{ cursor: "pointer" }}>
      <CardMedia
        component="img"
        alt={productName}
        height="140"
        width="140"
        style={{ objectFit: "cover", width: "100%", height: "100%" }}
        image={imageUrl}
      />
      <CardContent>
        <Typography variant="body2" color="text.secondary">
          {`${category} | ${subcategory}`}
        </Typography>
        <Typography variant="h6" component="div">
          {productName}
        </Typography>

        <Typography variant="subtitle1" color="text.primary">
          Stock: {stock}
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          size="small"
          color="primary"
          onClick={() => onAddStock(productId)}
        >
          Tambah Stok
        </Button>
        <Button
          size="small"
          color="primary"
          onClick={() => onStockOut(productId)}
        >
          Stok Keluar
        </Button>
        <Button
          size="small"
          color="primary"
          onClick={() => onViewStock(productId)}
        >
          Detil Stok
        </Button>
      </CardActions>
    </Card>
  );
};

export default InventoryCard;
