// src/components/products/Vendor/EditVendor.js
import React, { useState, useEffect } from "react";
import {
  Typography,
  TextField,
  Button,
  Container,
  Paper,
  ThemeProvider,
  Grid,
  CssBaseline,
  AppBar,
  Toolbar,
  IconButton,
  Modal,
  Box,
} from "@mui/material";
import theme from "../../../theme"; // Import tema kustom Anda
import { useParams } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import SendIcon from "@mui/icons-material/Send";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 5,
};

const Input = styled("input")({
  display: "none",
});

const EditVendor = () => {
  // Menggunakan useParams untuk mendapatkan nilai vendorId dari URL
  const { vendorId } = useParams();
  const [vendorData, setVendorData] = useState({
    nama_vendor: "",
    telepon: "",
    email: "",
    alamat: "",
    logo: null,
  });
  const [dataFetched, setDataFetched] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const [modalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const handleOpenModal = (message) => {
    setModalMessage(message);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    navigate("/products/vendor"); // Arahkan pengguna ke '/products/vendor' saat modal ditutup
  };

  const navigateToAddVendor = () => {
    navigate("/products/add-vendor");
  };

  const navigateToVendorPage = () => {
    navigate("/products/vendor");
  };

  const [currentLogoUrl, setCurrentLogoUrl] = useState(null);

  useEffect(() => {
    console.log("Fetching data for vendor ID:", vendorId);

    const fetchData = async () => {
      try {
        if (vendorId) {
          const response = await fetch(
            `https://server.ajeg.co.id/api/vendors/${vendorId}`
          );
          if (response.ok) {
            const data = await response.json();
            console.log("Data yang diambil dari server:", data);

            setVendorData(data);

            // Set URL logo saat ini jika ada
            if (data.logo) {
              setCurrentLogoUrl(`https://server.ajeg.co.id/${data.logo}`);
            }
          } else {
            console.error("Gagal mengambil data vendor.");
          }
        }
      } catch (error) {
        console.error("Terjadi kesalahan:", error);
      } finally {
        setDataFetched(true);
        setLoading(false);
      }
    };

    fetchData();
  }, [vendorId]);

  const handleBack = () => {
    // Implementasi navigasi kembali
    navigate(-1); // Untuk kembali ke halaman sebelumnya
  };
  const handleChange = (field, value) => {
    setVendorData({ ...vendorData, [field]: value });
  };

  const handleLogoChange = (event) => {
    const file = event.target.files[0];

    // Menampilkan preview logo yang baru
    const reader = new FileReader();
    reader.onload = (e) => {
      setCurrentLogoUrl(e.target.result); // Pindahkan ke dalam blok onload
    };
    reader.readAsDataURL(file);

    // Set state dengan file logo yang baru
    setVendorData({ ...vendorData, logo: file });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("nama_vendor", vendorData.nama_vendor);
    formData.append("telepon", vendorData.telepon);
    formData.append("email", vendorData.email);
    formData.append("alamat", vendorData.alamat);
    formData.append("logo", vendorData.logo);

    try {
      const response = await fetch(
        `https://server.ajeg.co.id/api/vendors/${vendorId}`,
        {
          method: "PUT",
          headers: {
            // Add any headers if needed
          },
          body: formData,
        }
      );

      if (response.ok) {
        handleOpenModal("Data vendor berhasil diubah.");
        // Lakukan pengalihan atau tindakan lain yang diperlukan setelah penyimpanan berhasil
      } else {
        handleOpenModal("Gagal mengubah data vendor.");
      }
    } catch (error) {
      handleOpenModal("Terjadi kesalahan:", error);
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar
        position="sticky"
        elevation={0}
        sx={{
          backgroundColor: theme.palette.background.appBar,
          color: "black",
          marginBottom: 2,
        }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="back"
            onClick={handleBack}
            sx={{
              margin: 1,
            }}
          >
            <KeyboardBackspaceIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, color: "black" }}
          >
            Edit Vendor
          </Typography>
          <Button
            sx={{
              paddingLeft: 2,
              paddingRight: 2,
              backgroundColor: theme.palette.primary.main,
              color: "white",
              "&:hover": {
                backgroundColor: theme.palette.primary.dark,
                "& svg": {
                  transform: "rotate(-45deg)",
                },
              },
              textTransform: "none",
              fontWeight: 500,
            }}
            startIcon={<SendIcon sx={{ transition: "transform 0.3s" }} />}
            onClick={handleSubmit}
          >
            Simpan Perubahan
          </Button>
        </Toolbar>
      </AppBar>
      <Container
        sx={{
          display: "flex",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <Paper
          elevation={0}
          sx={{ padding: theme.spacing(4), width: "100%", maxWidth: 500 }}
        >
          <Typography variant="h6" gutterBottom component="div">
            Edit Data Vendor
          </Typography>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Nama Vendor"
                  variant="outlined"
                  value={vendorData.nama_vendor}
                  onChange={(e) => handleChange("nama_vendor", e.target.value)}
                  helperText={`Harus dalam format Capitalize contoh 'Kinami' huruf K besar`}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Telepon"
                  variant="outlined"
                  value={vendorData.telepon}
                  onChange={(e) => handleChange("telepon", e.target.value)}
                  helperText={`Contoh format email yang valid 081381448898`}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Email"
                  variant="outlined"
                  value={vendorData.email}
                  onChange={(e) => handleChange("email", e.target.value)}
                  helperText={
                    "Contoh format email yang valid 'sofakinami@gmail.com'"
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Alamat"
                  variant="outlined"
                  value={vendorData.alamat}
                  onChange={(e) => handleChange("alamat", e.target.value)}
                  helperText={`Harus dalam format Capitalize contoh 'Sleman' huruf S besar`}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" gutterBottom>
                  Logo Saat Ini:
                </Typography>
                {vendorData.url_logo ? (
                  <img
                    src={vendorData.url_logo}
                    alt="Logo Saat Ini"
                    style={{ maxWidth: "100%", maxHeight: "150px" }}
                  />
                ) : (
                  <Typography variant="body2" color="textSecondary">
                    Tidak ada logo saat ini.
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" gutterBottom>
                  Logo Baru:
                </Typography>
                {vendorData.logo ? (
                  <img
                    src={URL.createObjectURL(vendorData.logo)}
                    alt="Logo Baru"
                    style={{ maxWidth: "100%", maxHeight: "150px" }}
                  />
                ) : (
                  <Typography variant="body2" color="textSecondary">
                    Tidak ada logo baru.
                  </Typography>
                )}
                <input
                  key={currentLogoUrl} // Tambahkan key di sini
                  accept="image/*"
                  id="icon-button-file"
                  type="file"
                  onChange={handleLogoChange}
                  style={{ display: "none" }}
                />
                <label htmlFor="icon-button-file">
                  <Button
                    fullWidth
                    variant="outlined"
                    color="primary"
                    component="span"
                    style={{ marginTop: "10px" }}
                  >
                    Ganti Logo
                  </Button>
                </label>
              </Grid>
            </Grid>
          </form>
        </Paper>
        <Modal
          open={modalOpen}
          onClose={handleCloseModal}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-title" variant="h6" component="h2">
              Notifikasi
            </Typography>
            <Typography id="modal-description" sx={{ mt: 2 }}>
              {modalMessage}
            </Typography>
            <Grid container spacing={2} sx={{ mt: 2 }}>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="outlined"
                  onClick={navigateToAddVendor}
                >
                  Tambahkan Vendor Baru
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={navigateToVendorPage}
                >
                  Kembali
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      </Container>
    </ThemeProvider>
  );
};

export default EditVendor;
