// App.js
import "./utils/console"; // Pastikan ini adalah import pertama
import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import theme from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import ProductModule from "./components/products/ProductModule";
import InventoryModule from "./components/inventory/InventoryModule";
import LoginPage from "./components/auth/Login";
import RegisterPage from "./components/auth/Register";
import { AuthProvider, useAuth } from "./context/AuthContext";

function App() {
  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <Routes>
            <Route path="/" element={<Navigate to="/products" />} />
            <Route
              path="/login"
              element={
                <GuestRoute>
                  <LoginPage />
                </GuestRoute>
              }
            />
            <Route
              path="/register"
              element={
                <GuestRoute>
                  <RegisterPage />
                </GuestRoute>
              }
            />
            <Route
              path="/products/*"
              element={
                <ProtectedRoute>
                  <ProductModule />
                </ProtectedRoute>
              }
            />

            <Route
              path="/inventory/*"
              element={
                <ProtectedRoute>
                  <InventoryModule />
                </ProtectedRoute>
              }
            />
            {/* Anda bisa menambahkan rute lainnya di sini */}
          </Routes>
        </Router>
      </ThemeProvider>
    </AuthProvider>
  );
}

function ProtectedRoute({ children }) {
  const { currentUser } = useAuth();
  return currentUser ? children : <Navigate to="/login" />;
}

function GuestRoute({ children }) {
  const { currentUser } = useAuth();
  return currentUser ? <Navigate to="/products" /> : children;
}

export default App;
