//AddProduct.js
import React, { useState, useCallback, useEffect } from "react";
import {
  Box,
  Grid,
  TextField,
  Typography,
  Button,
  AppBar,
  Toolbar,
  FormControl,
  FormControlLabel,
  Checkbox,
  InputLabel,
  Select,
  MenuItem,
  Paper,
  InputAdornment,
  IconButton,
  Modal,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import SendIcon from "@mui/icons-material/Send";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles"; // Import useTheme untuk mengakses tema

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 5,
};

// Komponen untuk mengelola upload gambar
const ImageUploadCard = ({ product, setProduct }) => {
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: useCallback(
      (acceptedFiles) => {
        setProduct((prevProduct) => ({
          ...prevProduct,
          files: acceptedFiles.map((file) =>
            Object.assign(file, { preview: URL.createObjectURL(file) })
          ),
        }));
      },
      [setProduct]
    ),
  });

  const handleRemoveImage = () => {
    if (product.files.length > 0) {
      URL.revokeObjectURL(product.files[0].preview);
    }
    setProduct((prevProduct) => ({ ...prevProduct, files: [] }));
  };

  return (
    <Box
      {...getRootProps()}
      sx={{
        border: "1px dashed #ccc",
        p: 2,
        mb: 2,
        textAlign: "center",
      }}
    >
      <input {...getInputProps()} />
      {product.files.length > 0 ? (
        <Box sx={{ position: "relative" }}>
          <img
            src={product.files[0].preview}
            alt="Preview"
            style={{ width: "100%", height: "auto" }}
          />
          <Button
            variant="contained"
            component="label"
            sx={{ position: "absolute", top: 16, left: 16, opacity: 0.7 }}
          >
            Ganti
            <input {...getInputProps()} />
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={handleRemoveImage}
            sx={{ position: "absolute", top: 16, right: 16, opacity: 0.7 }}
          >
            Hapus
          </Button>
        </Box>
      ) : (
        <>
          <CloudUploadIcon sx={{ fontSize: 50 }} />
          <Typography>Tambahkan Foto Produk di sini</Typography>
        </>
      )}
    </Box>
  );
};

// Komponen untuk mengelola spesifikasi khusus
const CustomSpecifications = ({
  product,
  setProduct,
  metadata,
  options = { subcategories: [] },
}) => {
  const [styles, setStyles] = useState([]);
  const [fabrics, setFabrics] = useState([]);
  const [seatings, setSeatings] = useState([]);
  const [kaki, setKaki] = useState([]);

  // Tetapkan subkategori yang memerlukan pilihan "Jenis Kain" dan "Jenis Kaki"
  const subcategoriesRequiringFabric = [
    "Beanbag",
    "Floor Seat",
    "Stool",
    "Puff",
    "Bench",
  ];
  const subcategoriesRequiringLegs = ["Stool", "Puff", "Bench"];

  // Cari nama subkategori berdasarkan id_subkategori yang dipilih
  const subcategoryName = options.subcategories.find(
    (subCat) => subCat.id_subkategori === product.subCategory
  )?.subkategori;

  // Cek apakah nama subkategori berada dalam array yang ditentukan
  const requiresFabricOption =
    subcategoriesRequiringFabric.includes(subcategoryName);
  const requiresLegsOption =
    subcategoriesRequiringLegs.includes(subcategoryName);

  useEffect(() => {
    // Mengambil data dari endpoint untuk spesifikasi default
    const fetchData = async () => {
      try {
        const stylesResponse = await axios.get(
          "https://server.ajeg.co.id/api/styles"
        );
        const fabricsResponse = await axios.get(
          "https://server.ajeg.co.id/api/fabrics"
        );
        const seatingsResponse = await axios.get(
          "https://server.ajeg.co.id/api/seatings"
        );
        const kakiResponse = await axios.get(
          "https://server.ajeg.co.id/api/legs"
        );

        const finishingsResponse = await axios.get(
          "https://server.ajeg.co.id/api/finishings"
        );

        setStyles(stylesResponse.data);
        setFabrics(fabricsResponse.data);
        setSeatings(seatingsResponse.data);
        setKaki(kakiResponse.data);

        console.log("Styles:", stylesResponse.data);
        console.log("Fabrics:", fabricsResponse.data);
        console.log("Seatings:", seatingsResponse.data);
        console.log("Kaki:", kakiResponse.data);
        console.log("Finishings:", finishingsResponse.data);
      } catch (error) {
        console.error("Terjadi kesalahan saat mengambil data:", error);
      }
    };

    fetchData();
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;

    // Log selected IDs to the console for specific fields
    if (["style", "jenisKain", "dudukan", "jenisFinishing"].includes(name)) {
      console.log(`Selected ${name} ID:`, value);
    }
    setProduct((prevProduct) => ({
      ...prevProduct,
      customSpecs: {
        ...(prevProduct.customSpecs || {}), // Pastikan customSpecs sudah diinisialisasi
        [name]: value,
      },
    }));

    if (name === "sku") {
      setProduct((prevProduct) => ({
        ...prevProduct,
        [name]: value,
      }));
    }
  };

  // Mengambil informasi spesifikasi khusus dari metadata
  const requiresSpecialSpecs =
    metadata.categories[product.category]?.requiresSpecialSpecs || false;

  console.log(subcategoryName, requiresFabricOption, requiresLegsOption);

  if (!requiresSpecialSpecs && !requiresFabricOption && !requiresLegsOption) {
    // Don't render the form if the category is not special or the vendor is not Kagumi
    return null;
  }

  return (
    <Box>
      {requiresSpecialSpecs && (
        <>
          <FormControl fullWidth margin="normal">
            <InputLabel>Style</InputLabel>
            <Select
              name="style"
              value={product.customSpecs?.style || ""}
              onChange={handleChange}
              label="Style"
            >
              {styles.map((style) => (
                <MenuItem key={style.id_style} value={style.id_style}>
                  {style.style}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel>Jenis Kain</InputLabel>
            <Select
              name="jenisKain"
              value={product.customSpecs?.jenisKain || ""}
              onChange={handleChange}
              label="Jenis Kain"
            >
              {fabrics.map((fabric) => (
                <MenuItem key={fabric.id_kain} value={fabric.id_kain}>
                  {fabric.kain} {/* Perbarui properti yang digunakan di sini */}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth margin="normal">
            <InputLabel>Dudukan</InputLabel>
            <Select
              name="dudukan"
              value={product.customSpecs?.dudukan || ""}
              onChange={handleChange}
              label="Dudukan"
            >
              {seatings.map((seating) => (
                <MenuItem key={seating.id_dudukan} value={seating.id_dudukan}>
                  {seating.dudukan}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel>Jenis Kaki</InputLabel>
            <Select
              name="kaki"
              value={product.customSpecs?.kaki || ""}
              onChange={handleChange}
              label="Jenis Kaki"
            >
              {kaki.map((leg) => (
                <MenuItem key={leg.id_kaki} value={leg.id_kaki}>
                  {leg.jenis_kaki}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            fullWidth
            label="Jumlah Bantal Peluk"
            name="jmlBantalPeluk"
            type="number"
            value={product.customSpecs?.jmlBantalPeluk || ""}
            onChange={handleChange}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Jumlah Bantal Sandaran"
            name="jmlBantalSandaran"
            type="number"
            value={product.customSpecs?.jmlBantalSandaran || ""}
            onChange={handleChange}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Jumlah Kantong Remot"
            name="jmlKantongRemot"
            type="number"
            value={product.customSpecs?.jmlKantongRemot || ""}
            onChange={handleChange}
            margin="normal"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={product.customSpecs?.includesPuff || false}
                onChange={(e) =>
                  setProduct((prevProduct) => ({
                    ...prevProduct,
                    customSpecs: {
                      ...(prevProduct.customSpecs || {}),
                      includesPuff: e.target.checked,
                    },
                  }))
                }
                name="includesPuff"
              />
            }
            label="Apakah termasuk Puff?"
          />
        </>
      )}
      {requiresFabricOption && (
        <FormControl fullWidth margin="normal">
          <InputLabel>Jenis Kain</InputLabel>
          <Select
            name="jenisKain"
            value={product.customSpecs?.jenisKain || ""}
            onChange={handleChange}
            label="Jenis Kain"
          >
            {fabrics.map((fabric) => (
              <MenuItem key={fabric.id_kain} value={fabric.id_kain}>
                {fabric.kain}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      {requiresLegsOption && (
        <FormControl fullWidth margin="normal">
          <InputLabel>Jenis Kaki</InputLabel>
          <Select
            name="kaki"
            value={product.customSpecs?.kaki || ""}
            onChange={handleChange}
            label="Jenis Kaki"
          >
            {kaki.map((leg) => (
              <MenuItem key={leg.id_kaki} value={leg.id_kaki}>
                {leg.jenis_kaki}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </Box>
  );
};

// ...

// Komponen utama AddProduct
const AddProduct = () => {
  const [metadata, setMetadata] = useState({
    categories: {},
    vendors: {},
  });
  // Tetapkan subkategori yang memerlukan pilihan "Jenis Kain" dan "Jenis Kaki"
  const subcategoriesRequiringFabric = [
    "Beanbag",
    "Floor Seat",
    "Stool",
    "Puff",
    "Bench",
  ];
  const subcategoriesRequiringLegs = ["Stool", "Puff", "Bench"];

  useEffect(() => {
    const fetchMetadata = async () => {
      try {
        const metadataResponse = await axios.get(
          "https://server.ajeg.co.id/api/metadata"
        );
        setMetadata(metadataResponse.data);
      } catch (error) {
        console.error("Error fetching metadata:", error);
      }
    };

    fetchMetadata();
  }, []);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const handleOpenModal = (message) => {
    setModalMessage(message);
    setModalOpen(true);
  };

  const handleCloseModal = () => {};

  const isFormValid = () => {
    // Memeriksa apakah semua input utama telah diisi
    if (
      !product.name ||
      !product.category ||
      !product.subCategory ||
      !product.vendor ||
      !product.productionTime ||
      !product.warrantyPeriod ||
      !product.length ||
      !product.width ||
      !product.height ||
      !product.salePrice ||
      !product.purchasePrice
    ) {
      alert("Harap isi semua field yang diperlukan sebelum menyimpan produk.");
      return false;
    }

    // Memeriksa apakah spesifikasi khusus sofa telah diisi jika diperlukan
    if (
      metadata.categories[product.category]?.requiresSpecialSpecs &&
      (!product.customSpecs ||
        !product.customSpecs.style ||
        !product.customSpecs.jenisKain ||
        !product.customSpecs.dudukan ||
        !product.customSpecs.kaki ||
        !product.customSpecs.jmlBantalPeluk ||
        !product.customSpecs.jmlBantalSandaran ||
        !product.customSpecs.jmlKantongRemot)
    ) {
      alert(
        "Harap isi semua spesifikasi khusus sofa sebelum menyimpan produk."
      );
      return false;
    }

    return true; // Mengembalikan true jika semua validasi terpenuhi
  };

  const [product, setProduct] = useState({
    name: "",
    category: "",
    subCategory: "",
    vendor: "",
    sku: "",
    productionTime: "",
    warrantyPeriod: "", // Tambahkan state untuk Masa Garansi
    length: "",
    width: "",
    height: "",
    description: "",
    salePrice: "",
    purchasePrice: "",
    files: [],
  });

  const [options, setOptions] = useState({
    categories: [],
    subcategories: [], // Berikan array kosong sebagai nilai default
    vendors: [],
  });

  // Mengambil data kategori dan vendor saat komponen dimuat
  useEffect(() => {
    const fetchData = async () => {
      try {
        const categoriesResponse = await axios.get(
          "https://server.ajeg.co.id/api/categories"
        );
        const vendorsResponse = await axios.get(
          "https://server.ajeg.co.id/api/vendors"
        );

        setOptions((prevOptions) => ({
          ...prevOptions,
          categories: categoriesResponse.data,
          vendors: vendorsResponse.data,
        }));

        console.log("Categories:", categoriesResponse.data);
        console.log("Vendors:", vendorsResponse.data);
      } catch (error) {
        console.error("Terjadi kesalahan saat mengambil data:", error);
      }
    };

    fetchData();
  }, []);

  const navigate = useNavigate();
  const theme = useTheme(); // Menggunakan hook useTheme untuk mengakses tema

  const navigateToAddProduct = () => {
    window.location.reload();
  };

  const navigateToProductsPage = () => {
    navigate("/products");
  };

  const handleChange = async (event) => {
    const { name, value } = event.target;

    // Log selected IDs to the console
    if (name === "category") {
      // Menyimpan kategori yang dipilih
      const selectedCategory = options.categories.find(
        (category) => category.id_kategori === value
      );

      try {
        // Fetch subcategories based on the selected category
        const response = await axios.get(
          `https://server.ajeg.co.id/api/subcategories/${value}`
        );

        console.log("Subcategories:", response.data);

        // Update the state with the fetched subcategories
        setOptions((prevOptions) => ({
          ...prevOptions,
          subcategories: response.data,
        }));
      } catch (error) {
        console.error("Error fetching subcategories:", error);
      }
    } else if (name === "subCategory") {
      // Menampilkan subkategori yang dipilih
    } else if (name === "vendor") {
      // Menyimpan vendor yang dipilih
      const selectedVendor = options.vendors.find(
        (vendor) => vendor.id_vendor === value
      );
    } else if (name === "name") {
      // Menampilkan nama produk yang diinputkan
    }

    // Memperbarui product.category dan product.subCategory
    if (name === "category") {
      // Pastikan untuk menghapus nilai subCategory saat kategori berubah
      setProduct((prevProduct) => ({
        ...prevProduct,
        [name]: value,
        subCategory: "",
      }));
    } else {
      setProduct((prevProduct) => ({
        ...prevProduct,
        [name]: value,
      }));
    }

    // Menampilkan rekap jika semua informasi sudah dimasukkan
    if (
      product.name &&
      product.category &&
      product.subCategory &&
      product.vendor
    ) {
      console.log("Rekap data:");

      // Menyimpan vendor, kategori, dan subkategori yang dipilih
      const selectedVendor = options.vendors.find(
        (vendor) => vendor.id_vendor === product.vendor
      );
      const selectedCategory = options.categories.find(
        (cat) => cat.id_kategori === product.category
      );
      const selectedSubcategory = options.subcategories.find(
        (subCat) => subCat.id_subkategori === product.subCategory
      );

      // Menampilkan rekap data yang sudah dimasukkan
      console.log("Typed Product Name:", product.name);
      console.log("Selected Category ID:", product.category);
      console.log(
        "Selected Category Name:",
        selectedCategory ? selectedCategory.kategori : ""
      );
      console.log("Selected Subcategory ID:", product.subCategory);
      console.log(
        "Selected Subcategory:",
        selectedSubcategory ? selectedSubcategory.subkategori : ""
      );
      console.log("Selected Vendor ID:", product.vendor);
      console.log(
        "Selected Vendor Name:",
        selectedVendor ? selectedVendor.nama_vendor : ""
      );
    }

    // Memanggil generateSKU untuk memperbarui SKU
    generateSKU();

    if (name === "warrantyPeriod") {
      setProduct((prevProduct) => ({
        ...prevProduct,
        warrantyPeriod: value,
      }));
    }
  };

  const generateSKU = () => {
    const vendorCode = product.vendor
      ? options.vendors
          .find((vendor) => vendor.id_vendor === product.vendor)
          .nama_vendor.slice(0, 3)
          .toUpperCase()
      : "XXX";

    const categoryCode = product.category
      ? options.categories
          .find((category) => category.id_kategori === product.category)
          .kategori.slice(0, 3)
          .toUpperCase()
      : "XXX";

    const subcategoryCode = product.subCategory
      ? options.subcategories
          .find(
            (subcategory) => subcategory.id_subkategori === product.subCategory
          )
          .subkategori.split(" ")
          .map((word) => word.slice(0, 3).toUpperCase()) // Mengambil 3 huruf pertama dari setiap kata
          .join("")
      : "XXX";

    const productNameCode = product.name
      ? product.name
          .split(" ")
          .map((word) => word.slice(0, 3).toUpperCase()) // Mengambil 3 huruf pertama dari setiap kata
          .join("")
          .slice(0, 6) // Mengambil 6 karakter pertama dari nama produk
      : "XXX";

    const sku = `${vendorCode}-${categoryCode}-${subcategoryCode}-${productNameCode}`;

    setProduct((prevProduct) => ({
      ...prevProduct,
      sku: sku,
    }));

    console.log("Generated SKU:", sku);
  };

  const handleDescriptionChange = (event) => {
    setProduct((prevProduct) => ({
      ...prevProduct,
      description: event.target.value,
    }));
  };

  const handleBack = () => {
    // Implementasi navigasi kembali
    navigate(-1); // Untuk kembali ke halaman sebelumnya
  };

  const subcategoryName = options.subcategories.find(
    (subCat) => subCat.id_subkategori === product.subCategory
  )?.subkategori;

  const requiresFabricOption =
    subcategoriesRequiringFabric.includes(subcategoryName);
  const requiresLegsOption =
    subcategoriesRequiringLegs.includes(subcategoryName);

  // Fungsi untuk menangani submit
  const handleSubmit = async () => {
    if (!isFormValid()) {
      handleOpenModal(
        "Harap isi semua field yang diperlukan sebelum menyimpan produk."
      );
      return;
    }

    const formData = new FormData();

    // Menambahkan data produk utama
    formData.append("nama", product.name);
    formData.append("id_kategori", product.category);
    formData.append("id_subkategori", product.subCategory);
    formData.append("id_vendor", product.vendor);
    formData.append("sku", product.sku);
    formData.append("estimasi_waktu_produksi", product.productionTime);
    formData.append("masa_garansi", product.warrantyPeriod); // Sesuaikan dengan kebutuhan server
    formData.append("panjang", product.length);
    formData.append("lebar", product.width);
    formData.append("tinggi", product.height);
    formData.append("deskripsi", product.description);
    formData.append("harga_jual", product.salePrice);
    formData.append("harga_beli", product.purchasePrice);

    // Menambahkan gambar produk
    if (product.files && product.files.length > 0) {
      formData.append("foto_produk", product.files[0]);
    }

    // Menambahkan spesifikasi khusus sofa
    if (
      product.customSpecs &&
      metadata.categories[product.category]?.requiresSpecialSpecs
    ) {
      const sofaData = {
        id_style: product.customSpecs.style,
        id_kain: product.customSpecs.jenisKain,
        id_dudukan: product.customSpecs.dudukan,
        id_kaki: product.customSpecs.kaki, // Asumsikan Anda memiliki ini di customSpecs
        bantal_peluk: product.customSpecs.jmlBantalPeluk,
        bantal_sandaran: product.customSpecs.jmlBantalSandaran,
        kantong_remot: product.customSpecs.jmlKantongRemot,
        puff: product.customSpecs.includesPuff || false, // Menangani nilai boolean
      };
      formData.append("sofa", JSON.stringify(sofaData));
    }

    console.log("requiresFabricOption:", requiresFabricOption);
    console.log("requiresLegsOption:", requiresLegsOption);

    // Menambahkan spesifikasi khusus berdasarkan subkategori jika diperlukan
    if (requiresFabricOption) {
      formData.append("jenisKain", product.customSpecs?.jenisKain);
      console.log("Jenis Kain:", product.customSpecs?.jenisKain);
    }

    if (requiresLegsOption) {
      formData.append("jenisKaki", product.customSpecs?.kaki);
      console.log("Jenis Kaki:", product.customSpecs?.kaki);
    }

    try {
      const response = await axios.post(
        "https://server.ajeg.co.id/api/products",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      handleOpenModal("Produk berhasil disimpan.");
      // Lakukan tindakan lanjutan seperti membersihkan form atau redirect
    } catch (error) {
      handleOpenModal("Error saat menyimpan produk: " + error.message);
      // Tampilkan pesan kesalahan di UI jika diperlukan
    }
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="sticky" // Mengatur posisi menjadi sticky
        elevation={0} // Menghilangkan shadow
        sx={{
          backgroundColor: theme.palette.background.appBar,
          color: "black", // Warna teks hitam
          marginBottom: 2,
        }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="back"
            onClick={handleBack}
            sx={{
              margin: 1,
            }}
          >
            <KeyboardBackspaceIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, color: "black" }} // Warna teks hitam
          >
            Tambah Produk
          </Typography>
          <Button
            sx={{
              paddingLeft: 2,
              paddingRight: 2,
              backgroundColor: theme.palette.primary.main, // Warna tombol sesuai tema
              color: "white", // Warna teks putih
              "&:hover": {
                backgroundColor: theme.palette.primary.dark, // Warna saat hover
                "& svg": {
                  transform: "rotate(-45deg)", // Memiringkan ikon 45 derajat saat tombol dihover
                },
              },
              textTransform: "none",
              fontWeight: 500,
            }}
            startIcon={<SendIcon sx={{ transition: "transform 0.3s" }} />}
            onClick={handleSubmit}
          >
            Simpan Produk
          </Button>
        </Toolbar>
      </AppBar>

      <Grid
        container
        spacing={3}
        sx={{ paddingLeft: "20px", paddingRight: "20px" }}
      >
        <Grid item xs={6} md={4}>
          <Paper sx={{ padding: 2, marginBottom: 3 }}>
            <Typography variant="subtitle1" gutterBottom>
              Foto Produk
            </Typography>
            <ImageUploadCard product={product} setProduct={setProduct} />
          </Paper>
          <Paper sx={{ padding: 2, marginBottom: 3 }}>
            <Typography variant="subtitle1" gutterBottom>
              Spesifikasi Khusus
            </Typography>
            <CustomSpecifications
              product={product}
              setProduct={setProduct}
              metadata={metadata}
              options={options}
              requiresFabricOption={requiresFabricOption}
              requiresLegsOption={requiresLegsOption}
            />
          </Paper>
        </Grid>

        <Grid item xs={6} md={8}>
          <Paper variant="outlined" sx={{ p: 2 }}>
            <Typography variant="subtitle1" gutterBottom>
              Informasi Produk
            </Typography>
            <TextField
              fullWidth
              label="Nama Produk"
              name="name"
              value={product.name}
              onChange={handleChange}
              margin="normal"
              helperText="Harus dalam format Capitalize contoh 'New Shizu' huruf N dan S besar"
            />
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl fullWidth margin="normal">
                  <InputLabel>Kategori</InputLabel>
                  <Select
                    name="category"
                    value={product.category}
                    onChange={handleChange}
                    label="Kategori"
                  >
                    {options.categories.map((category) => (
                      <MenuItem
                        key={category.id_kategori}
                        value={category.id_kategori}
                      >
                        {category.kategori}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth margin="normal">
                  <InputLabel>Subkategori</InputLabel>
                  <Select
                    name="subCategory"
                    value={product.subCategory || ""}
                    onChange={handleChange}
                    label="Subkategori"
                  >
                    {options.subcategories &&
                      options.subcategories.map((subCategory) => (
                        <MenuItem
                          key={subCategory.id_subkategori}
                          value={subCategory.id_subkategori}
                        >
                          {subCategory.subkategori}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <FormControl fullWidth margin="normal">
              <InputLabel>Vendor</InputLabel>
              <Select
                name="vendor"
                value={product.vendor}
                onChange={handleChange}
                label="Vendor"
              >
                {options.vendors.map((vendor) => (
                  <MenuItem key={vendor.id_vendor} value={vendor.id_vendor}>
                    {vendor.nama_vendor}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              fullWidth
              label="SKU - dibuat secara otomatis"
              name="sku"
              value={product.sku}
              onChange={handleChange}
              margin="normal"
              InputProps={{
                readOnly: true, // Membuat input SKU menjadi hanya baca
              }}
            />

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Estimasi Waktu Produksi"
                  name="productionTime"
                  type="number"
                  value={product.productionTime}
                  onChange={handleChange}
                  margin="normal"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">Hari</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Masa Garansi (tahun)"
                  name="warrantyPeriod"
                  type="number"
                  value={product.warrantyPeriod}
                  onChange={handleChange}
                  margin="normal"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">Tahun</InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <Typography variant="subtitle1" marginTop={2}>
              Dimensi Produk
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label="Panjang"
                  name="length"
                  type="number"
                  value={product.length}
                  onChange={handleChange}
                  margin="normal"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">cm</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label="Lebar"
                  name="width"
                  type="number"
                  value={product.width}
                  onChange={handleChange}
                  margin="normal"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">cm</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label="Tinggi"
                  name="height"
                  type="number"
                  value={product.height}
                  onChange={handleChange}
                  margin="normal"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">cm</InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <TextField
              label="Deskripsi Produk"
              multiline
              rows={4}
              value={product.description}
              onChange={handleDescriptionChange}
              variant="outlined"
              fullWidth
              margin="normal"
            />
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Harga Jual"
                  name="salePrice"
                  type="number"
                  value={product.salePrice}
                  onChange={handleChange}
                  margin="normal"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">Rp</InputAdornment>
                    ),
                  }}
                />
                {/* Menampilkan harga jual dengan format pemisah ribuan */}
                <Typography variant="body2" color="textSecondary">
                  {`Rp. ${Number(product.salePrice).toLocaleString("id-ID")}`}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Harga Beli"
                  name="purchasePrice"
                  type="number"
                  value={product.purchasePrice}
                  onChange={handleChange}
                  margin="normal"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">Rp</InputAdornment>
                    ),
                  }}
                />

                {/* Menampilkan harga jual dengan format pemisah ribuan */}
                <Typography variant="body2" color="textSecondary">
                  {`Rp. ${Number(product.purchasePrice).toLocaleString(
                    "id-ID"
                  )}`}
                </Typography>
              </Grid>
            </Grid>
            {/* ... Tambahkan lebih banyak field sesuai kebutuhan */}
          </Paper>
        </Grid>
      </Grid>
      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-title" variant="h6" component="h2">
            Notifikasi
          </Typography>
          <Typography id="modal-description" sx={{ mt: 2 }}>
            {modalMessage}
          </Typography>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="outlined"
                onClick={navigateToAddProduct}
              >
                Tambahkan Produk Baru
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                onClick={navigateToProductsPage}
              >
                Kembali
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </Box>
  );
};

export default AddProduct;
