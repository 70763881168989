// src/components/auth/Login.js
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import {
  Grid,
  TextField,
  Button,
  Typography,
  Link,
  FormControlLabel,
  Checkbox,
  useMediaQuery, // Import useMediaQuery for responsiveness
  Modal, // Import Modal from Material-UI
} from "@mui/material";
import loginBackground from "../../assets/img/loginbackground.png";
import theme from "../../theme";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const Login = () => {
  const isMobile = useMediaQuery("(max-width:600px)"); // Check if screen size is mobile
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false); // State to manage error modal
  const [errorMessage, setErrorMessage] = useState(""); // State to store error message
  const { login } = useAuth();
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser) {
      navigate("/products");
    }
  }, [currentUser, navigate]);

  const handleLogin = async (event) => {
    event.preventDefault();
    console.log("Attempting to login with:", email, password);

    try {
      await login(email, password);
      console.log("Login successful, navigating to /products");

      navigate("/products");
    } catch (error) {
      console.error("Login Error:", error);
      // Show error modal with the error message
      setErrorMessage("Email / Username atau Kata Sandi Salah!");
      setErrorModalOpen(true);
    }
  };

  const handleCloseErrorModal = () => {
    setErrorModalOpen(false);
  };

  return (
    <Grid container sx={{ minHeight: "100vh" }}>
      {!isMobile && (
        <Grid
          item
          xs={7}
          sx={{
            backgroundColor: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            overflow: "hidden",
            position: "relative",
          }}
        >
          <img
            src={loginBackground}
            alt="Login Background"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
          <Grid
            sx={{
              left: 0,
              right: 0,
              position: "absolute",
              bottom: 0,
              padding: 2,
              backgroundColor: "rgba(255, 255, 255, 0.7)",
              paddingBottom: 5,
              paddingTop: 5,
            }}
          >
            <Typography variant="subtitle">Selamat Datang Di </Typography>
            <Typography
              variant="h3"
              sx={{
                fontWeight: "bold",
                color: theme.palette.primary.main,
              }}
            >
              Furnindo Factory
            </Typography>
            <Typography variant="subtitle">
              Jika kamu belum memiliki akun, hubungi Team Management!
            </Typography>
          </Grid>
        </Grid>
      )}

      <Grid
        item
        xs={12}
        sm={5}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          p: isMobile ? 2 : 4,
        }}
      >
        <Typography variant="h5" mb={2} sx={{ fontWeight: 600 }}>
          Hai, Selamat Datang!
        </Typography>
        <Typography
          variant="subtitle"
          sx={{
            textAlign: "center",
          }}
        >
          Masukkan email / username dan kata sandi kamu untuk login.{" "}
        </Typography>
        <form onSubmit={handleLogin}>
          <TextField
            fullWidth
            label="Masukkan Email / Username"
            variant="outlined"
            margin="normal"
            sx={{ mb: 2 }}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            fullWidth
            label="Kata Sandi"
            type={showPassword ? "text" : "password"}
            variant="outlined"
            margin="normal"
            sx={{ mb: 2 }}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="large"
            sx={{ mb: 2 }}
            fullWidth
          >
            Masuk
          </Button>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <FormControlLabel
                control={<Checkbox color="primary" />}
                label="Ingat Saya"
              />
            </Grid>
            <Grid item>
              <Link href="#" underline="hover" sx={{ mb: 3 }}>
                Lupa Sandi?
              </Link>
            </Grid>
          </Grid>
        </form>
        <Grid
          sx={{
            position: "absolute",
            bottom: 10,
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="subtitle">
            Furnindo-v.1.1.0-2023-12-10
          </Typography>
        </Grid>
      </Grid>

      <Modal
        open={errorModalOpen}
        onClose={handleCloseErrorModal}
        aria-labelledby="error-modal-title"
        aria-describedby="error-modal-description"
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 300,
            backgroundColor: "white",
            padding: 20,
            borderRadius: 4,
            textAlign: "center",
          }}
        >
          <Typography variant="h6" id="error-modal-title">
            Otentikasi Tidak Valid
          </Typography>
          <Typography variant="body1" id="error-modal-description">
            {errorMessage}
          </Typography>
          <Button onClick={handleCloseErrorModal} color="primary">
            Close
          </Button>
        </div>
      </Modal>
    </Grid>
  );
};

export default Login;
