//AddCategory.js
import React, { useState } from "react";
import {
  TextField,
  Button,
  Grid,
  Typography,
  Paper,
  AppBar,
  Toolbar,
  IconButton,
  CssBaseline,
  Modal,
  Box,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import theme from "../../../theme";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import SendIcon from "@mui/icons-material/Send";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 5,
};

const AddCategory = () => {
  const [kategori, setKategori] = useState("");
  const [subkategoriFields, setSubkategoriFields] = useState([""]);

  const navigate = useNavigate();

  const [modalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const handleOpenModal = (message) => {
    setModalMessage(message);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    navigate("/products/category"); // Arahkan pengguna ke '/products/vendor' saat modal ditutup
  };

  const navigateToAddCategory = () => {
    navigate("/products/add-category");
  };

  const navigateToCategoryPage = () => {
    navigate("/products/category");
  };

  const handleBack = () => {
    // Implementasi navigasi kembali
    navigate(-1); // Untuk kembali ke halaman sebelumnya
  };

  const handleAddCategory = async () => {
    try {
      // Kirim data ke server (Anda perlu mengimplementasikan logika di sisi server)
      const response = await fetch("https://server.ajeg.co.id/api/categories", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          kategori,
          subkategori: subkategoriFields,
        }),
      });

      if (response.ok) {
        handleOpenModal("Kategori berhasil ditambahkan!");
        // Opsional, Anda dapat mengarahkan pengguna atau melakukan tindakan lain
      } else {
        handleOpenModal("Gagal menambahkan kategori.");
      }
    } catch (error) {
      handleOpenModal("Terjadi kesalahan:", error);
    }
  };

  const handleSubkategoriChange = (index, value) => {
    const updatedSubkategoriFields = [...subkategoriFields];
    updatedSubkategoriFields[index] = value;
    setSubkategoriFields(updatedSubkategoriFields);
  };

  const handleAddSubkategoriField = () => {
    setSubkategoriFields([...subkategoriFields, ""]);
  };

  const handleRemoveSubkategoriField = (index) => {
    const updatedSubkategoriFields = [...subkategoriFields];
    updatedSubkategoriFields.splice(index, 1);
    setSubkategoriFields(updatedSubkategoriFields);
  };

  return (
    <CssBaseline>
      <AppBar
        position="sticky"
        elevation={0}
        sx={{
          backgroundColor: theme.palette.background.appBar,
          color: "black",
          marginBottom: 2,
        }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="back"
            onClick={handleBack}
            sx={{
              margin: 1,
            }}
          >
            <KeyboardBackspaceIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, color: "black" }}
          >
            Tambah Kategori
          </Typography>
          <Button
            sx={{
              paddingLeft: 2,
              paddingRight: 2,
              backgroundColor: theme.palette.primary.main,
              color: "white",
              "&:hover": {
                backgroundColor: theme.palette.primary.dark,
                "& svg": {
                  transform: "rotate(-45deg)",
                },
              },
              textTransform: "none",
              fontWeight: 500,
            }}
            startIcon={<SendIcon sx={{ transition: "transform 0.3s" }} />}
            onClick={handleAddCategory}
          >
            Simpan Kategori
          </Button>
        </Toolbar>
      </AppBar>
      <Grid container justifyContent="center" style={{ height: "100vh" }}>
        <Grid item xs={6}>
          <Paper elevation={0} style={{ padding: "20px", textAlign: "center" }}>
            <Typography variant="h5" gutterBottom>
              Tambah Kategori
            </Typography>
            <TextField
              label="Kategori"
              fullWidth
              variant="outlined"
              margin="normal"
              value={kategori}
              onChange={(e) => setKategori(e.target.value)}
              helperText="Harus dalam format Capitalize contoh 'Meja' huruf M besar"
            />
            {subkategoriFields.map((subkategori, index) => (
              <div
                key={index}
                style={{ display: "flex", alignItems: "center" }}
              >
                <TextField
                  label={`Subkategori ${index + 1}`}
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  value={subkategori}
                  onChange={(e) =>
                    handleSubkategoriChange(index, e.target.value)
                  }
                  helperText="Harus dalam format Capitalize contoh 'Meja Belajar' huruf M dan B besar"
                />
                <IconButton
                  onClick={() => handleRemoveSubkategoriField(index)}
                  style={{ marginLeft: "10px" }}
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            ))}
            <Button
              fullWidth
              variant="outlined"
              color="primary"
              onClick={handleAddSubkategoriField}
              style={{ marginTop: "10px" }}
            >
              <AddIcon />
              Add Subcategory
            </Button>
          </Paper>
        </Grid>
      </Grid>
      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-title" variant="h6" component="h2">
            Notifikasi
          </Typography>
          <Typography id="modal-description" sx={{ mt: 2 }}>
            {modalMessage}
          </Typography>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="outlined"
                onClick={navigateToAddCategory}
              >
                Tambahkan Kategori Baru
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                onClick={navigateToCategoryPage}
              >
                Kembali
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </CssBaseline>
  );
};

export default AddCategory;
