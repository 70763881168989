// src/components/auth/Register.js
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import {
  Grid,
  TextField,
  Button,
  Typography,
  Link,
  useMediaQuery,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import loginBackground from "../../assets/img/loginbackground.png";
import theme from "../../theme";

const Register = () => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const [userData, setUserData] = useState({
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const { register } = useAuth();
  const navigate = useNavigate();

  const handleChange = (e) => {
    if (e.target.name === "username") {
      e.target.value = e.target.value.replace(/\s/g, "");
    }
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const handleRegister = async (event) => {
    event.preventDefault();

    if (userData.password !== userData.confirmPassword) {
      alert("Kata sandi dan konfirmasi kata sandi tidak cocok!");
      return;
    }

    if (userData.username.includes(" ")) {
      alert("Username/nama pengguna tidak boleh mengandung spasi.");
      return;
    }

    try {
      await register(userData.username, userData.email, userData.password);
      navigate("/login");
    } catch (error) {
      console.error("Registrasi Error:", error);
    }
  };

  return (
    <Grid container sx={{ minHeight: "100vh" }}>
      {!isMobile && (
        <Grid
          item
          xs={7}
          sx={{
            backgroundColor: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            overflow: "hidden",
            position: "relative",
          }}
        >
          <img
            src={loginBackground}
            alt="Login Background"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
          <Grid
            sx={{
              left: 0,
              right: 0,
              position: "absolute",
              bottom: 0,
              padding: 2,
              backgroundColor: "rgba(255, 255, 255, 0.7)",
              paddingBottom: 5,
              paddingTop: 5,
            }}
          >
            <Typography variant="subtitle">Selamat Datang Di </Typography>
            <Typography
              variant="h3"
              sx={{
                fontWeight: "bold",
                color: theme.palette.primary.main,
              }}
            >
              Furnindo Factory
            </Typography>
            <Typography variant="subtitle">
              Jika kamu belum memiliki akun, hubungi Team Management!
            </Typography>
          </Grid>
        </Grid>
      )}

      <Grid
        item
        xs={12}
        sm={5}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          p: isMobile ? 2 : 4,
        }}
      >
        <Typography variant="h5" mb={2} sx={{ fontWeight: 600 }}>
          Hai, Selamat Datang!
        </Typography>
        <Typography
          variant="subtitle"
          sx={{
            textAlign: "center",
          }}
        >
          Isi informasi di bawah untuk mendaftar.
        </Typography>
        <form onSubmit={handleRegister}>
          <TextField
            fullWidth
            label="Username / Nama Pengguna"
            variant="outlined"
            margin="normal"
            sx={{ mb: 1 }}
            name="username"
            value={userData.username}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            label="Email"
            variant="outlined"
            margin="normal"
            sx={{ mb: 1 }}
            name="email"
            value={userData.email}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            label="Kata Sandi"
            type={showPassword ? "text" : "password"}
            variant="outlined"
            margin="normal"
            sx={{ mb: 1 }}
            name="password"
            value={userData.password}
            onChange={handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            fullWidth
            label="Konfirmasi Kata Sandi"
            type={showPassword ? "text" : "password"}
            variant="outlined"
            margin="normal"
            sx={{ mb: 2 }}
            name="confirmPassword"
            value={userData.confirmPassword}
            onChange={handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="large"
            sx={{ mb: 1 }}
            fullWidth
          >
            Daftar
          </Button>
          <Link href="/login" underline="hover" sx={{ mb: 3 }}>
            Sudah punya akun? Masuk di sini
          </Link>
        </form>
      </Grid>
    </Grid>
  );
};

export default Register;
