import React, { useState, useEffect } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Box,
  Typography,
  TextField,
} from "@mui/material";
import axios from "axios";

const StockOutForm = ({ selectedProduct, onClose }) => {
  const [jumlahStokKeluar, setJumlahStokKeluar] = useState(0);
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [skuReferensi, setSkuReferensi] = useState(""); // SKU digunakan sebagai referensi
  const [skuAkhir, setSkuAkhir] = useState("");

  // State untuk menentukan apakah produk adalah sofa
  const [isSofa, setIsSofa] = useState(false);

  const [kategori, setKategori] = useState({});
  const [customers, setCustomers] = useState({});

  useEffect(() => {
    // Fetch kategori dan customer dari server
    axios
      .get("https://server.ajeg.co.id/api/inventory/kategori")
      .then((response) => {
        const kategoriData = {};
        response.data.forEach((kat) => {
          kategoriData[kat.id_kategori] = kat.kategori;
        });
        setKategori(kategoriData);
      })
      .catch((error) => {
        console.error("Error fetching kategori:", error);
      });

    axios
      .get("https://server.ajeg.co.id/api/inventory/customers")
      .then((response) => {
        const customerData = {};
        response.data.forEach((customer) => {
          customerData[customer.id_customer] = customer.nama_customer;
        });
        setCustomers(customerData);
      })
      .catch((error) => {
        console.error("Error fetching customers:", error);
      });
  }, []);

  useEffect(() => {
    if (
      selectedProduct &&
      Object.keys(kategori).length &&
      Object.keys(customers).length
    ) {
      const kategoriProduk = kategori[selectedProduct.id_kategori];

      setIsSofa(kategoriProduk === "Sofa");
    }
  }, [selectedProduct, kategori, customers]);

  useEffect(() => {
    if (selectedProduct) {
      setSkuAkhir(generateSkuAkhir());
    }
  }, [selectedProduct, selectedCustomer, isSofa]);

  const generateSkuAkhir = () => {
    let skuAwal =
      selectedProduct && selectedProduct.sku ? selectedProduct.sku : "";

    return `${skuAwal}-OUT`;
  };

  const handleJumlahStokKeluarChange = (event) => {
    setJumlahStokKeluar(event.target.value);
  };

  const handleCustomerChange = (event) => {
    setSelectedCustomer(event.target.value);
  };

  // Fungsi untuk menghandle perubahan pada field SKU Referensi
  const handleSkuReferensiChange = (event) => {
    setSkuReferensi(event.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let dataStokKeluar = {
      id_produk: selectedProduct.id_produk,
      jumlah_stok_keluar: jumlahStokKeluar,
      sku_referensi: skuReferensi, // Menggunakan SKU sebagai referensi
      sku_akhir: skuAkhir,
      id_customer: selectedCustomer,
    };

    // Mengirim data ke server
    axios
      .post(
        "https://server.ajeg.co.id/api/inventory/stokkeluar",
        dataStokKeluar
      )
      .then((response) => {
        console.log("Data berhasil disimpan:", response.data);
        onClose(); // Tutup form setelah submit berhasil
      })
      .catch((error) => {
        console.error("Error saat menyimpan data:", error);
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box display="flex" flexDirection="column" gap={2}>
        <Typography>Produk: {selectedProduct?.nama_produk}</Typography>

        <FormControl fullWidth>
          <InputLabel>Pelanggan</InputLabel>
          <Select
            value={selectedCustomer}
            label="Pelanggan"
            onChange={handleCustomerChange}
          >
            {Object.keys(customers).map((customerId) => (
              <MenuItem key={customerId} value={customerId}>
                {customers[customerId]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          label="SKU Referensi - Gunakan SKU sebagai referensi"
          value={skuReferensi}
          onChange={handleSkuReferensiChange}
          fullWidth
        />

        <TextField
          label="Jumlah Stok Keluar"
          type="number"
          value={jumlahStokKeluar}
          onChange={handleJumlahStokKeluarChange}
          fullWidth
        />
        <TextField
          label="SKU Akhir - dibuat secara otomatis"
          disabled
          value={skuAkhir}
          fullWidth
        />

        <Button type="submit" variant="contained" color="primary">
          Submit
        </Button>
      </Box>
    </form>
  );
};

export default StockOutForm;
