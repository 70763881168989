import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: '"Poppins", sans-serif',
    variantMapping: {
      h1: "h5",
      h2: "h5",
      h3: "h5",
      h4: "h5",
    },
  },
  palette: {
    primary: {
      main: "#086D59",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#f50057",
    },
    background: {
      appBar: "#EFEFEF", // Tambahkan warna latar belakang AppBar di sini
    },
    // Tambahkan lebih banyak warna jika diperlukan
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "8px", // Menambahkan borderRadius untuk membuat tombol lebih bulat
          textTransform: "none", // Tambahkan styling lain yang Anda inginkan untuk tombol di sini
        },
      },
    },
    // Customisasi komponen lain jika diperlukan
  },
  // Tambahkan pengaturan lain jika diperlukan
});

export default theme;
