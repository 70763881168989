// src/components/products/FabricList.js
import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Grid,
  Typography,
  Button,
  IconButton,
  AppBar,
  Toolbar,
  Modal,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";
import theme from "../../../theme"; // Sesuaikan path sesuai struktur proyek Anda
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 5,
};

const FabricList = () => {
  const [fabrics, setFabrics] = useState([]);
  const navigate = useNavigate();

  const [modalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const handleOpenModal = (message) => {
    setModalMessage(message);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const navigateToAdd = () => {
    navigate("/products/add-fabric");
  };

  const navigateToPage = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    // Mengambil data dari server saat komponen dimuat
    fetch("https://server.ajeg.co.id/api/fabrics")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        // Pastikan data yang diterima adalah array
        if (Array.isArray(data)) {
          setFabrics(data);
        } else {
          throw new Error("Data is not an array");
        }
      })
      .catch((error) => console.error("Terjadi kesalahan:", error));
  }, []);

  const handleEditFabric = (fabricId) => {
    // Navigasi ke halaman EditFabric dengan parameter fabricId
    navigate(`/products/edit-fabric/${fabricId}`);
  };

  const handleDeleteFabric = async (fabricId) => {
    try {
      const response = await fetch(
        `https://server.ajeg.co.id/api/fabrics/${fabricId}`,
        {
          method: "DELETE",
        }
      );

      if (response.ok) {
        handleOpenModal(`Kain dengan ID ${fabricId} berhasil dihapus.`);

        // Perbarui status lokal setelah penghapusan berhasil
        setFabrics((prevFabrics) =>
          prevFabrics.filter((fabric) => fabric.id_kain !== fabricId)
        );
      } else {
        handleOpenModal(`Gagal menghapus kain dengan ID ${fabricId}.`);
      }
    } catch (error) {
      handleOpenModal("Terjadi kesalahan:", error);
    }
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="sticky"
        elevation={0}
        sx={{
          backgroundColor: theme.palette.background.appBar,
          color: "black",
        }}
      >
        <Toolbar>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h6" sx={{ flexGrow: 1, color: "black" }}>
                Daftar Kain
              </Typography>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                component={Link}
                to="/products/add-fabric"
                sx={{
                  textTransform: "none",
                  fontWeight: 500,
                  "&:hover": {
                    "& svg": {
                      transform: "rotate(45deg)", // Memiringkan ikon 45 derajat saat tombol dihover
                    },
                  },
                }}
                startIcon={<AddIcon sx={{ transition: "transform 0.3s" }} />}
              >
                Tambahkan Kain
              </Button>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Grid sx={{ padding: 3 }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead
              sx={{
                backgroundColor: "#f5f5f5",
              }}
            >
              <TableRow>
                <TableCell>No</TableCell>
                <TableCell>Nama Kain</TableCell>
                <TableCell>Variasi Warna</TableCell>
                <TableCell>Aksi</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fabrics.map((fabric, index) => (
                <TableRow
                  sx={{
                    backgroundColor: "white",
                    "&:hover": {
                      backgroundColor: "#f5f5f5",
                    },
                  }}
                  key={fabric.id_kain}
                >
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{fabric.kain}</TableCell>
                  <TableCell>{fabric.variasi_warna.join(", ")}</TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => handleEditFabric(fabric.id_kain)}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => handleDeleteFabric(fabric.id_kain)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-title" variant="h6" component="h2">
            Notifikasi
          </Typography>
          <Typography id="modal-description" sx={{ mt: 2 }}>
            {modalMessage}
          </Typography>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12}>
              <Button fullWidth variant="outlined" onClick={navigateToAdd}>
                Tambahkan Kain Baru
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button fullWidth variant="contained" onClick={navigateToPage}>
                Kembali
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </Box>
  );
};

export default FabricList;
