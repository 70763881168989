//src / components / products / ProductCard.js

import React from "react";
import { Card, CardContent, CardMedia, Typography } from "@mui/material";

const ProductCard = ({
  name,
  category,
  subcategory,
  vendorLogo,
  price,
  imageUrl,
  onClick,
}) => {
  return (
    <Card onClick={onClick} style={{ cursor: "pointer" }}>
      {" "}
      {/* Tambahkan onClick dan style cursor */}
      <CardMedia
        component="img"
        alt={name}
        height="140"
        width="140"
        style={{ objectFit: "cover", width: "100%", height: "100%" }}
        image={imageUrl}
      />
      <CardContent>
        <Typography variant="body2" color="text.secondary">
          {`${category} | ${subcategory}`}
        </Typography>
        <Typography variant="h6" component="div">
          {name}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {vendorLogo && (
            <img
              src={vendorLogo}
              alt="Vendor Logo"
              style={{ maxWidth: 50, maxHeight: 50 }}
            />
          )}
        </Typography>
        <Typography variant="subtitle1" color="text.primary">
          {`Rp ${Number(price).toLocaleString("id-ID")}`}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default ProductCard;
