//src/components/products/ProductList.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import ProductCard from "./ProductCard";
import {
  Grid,
  Icon,
  IconButton,
  Typography,
  AppBar,
  Toolbar,
  Button,
  Tabs,
  Tab,
  TextField, // Import TextField
  InputAdornment, // Import tambahan
} from "@mui/material";
import theme from "../../theme";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search"; // Import SearchIcon

const ProductList = () => {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]); // State untuk daftar kategori
  const [activeTab, setActiveTab] = useState(0);
  const [searchQuery, setSearchQuery] = useState(""); // State baru untuk query pencarian
  const navigate = useNavigate();

  useEffect(() => {
    // Mengambil daftar kategori dari server
    axios
      .get("https://server.ajeg.co.id/api/categories")
      .then((response) => {
        setCategories(response.data);
      })
      .catch((error) => {
        console.error("Terjadi kesalahan saat mengambil data kategori:", error);
      });

    // Mengambil daftar produk dari server
    axios
      .get("https://server.ajeg.co.id/api/products")
      .then((response) => {
        setProducts(response.data);
      })
      .catch((error) => {
        console.error("Terjadi kesalahan saat mengambil data produk:", error);
      });
  }, []);

  const handleCardClick = (productId, productName) => {
    const formattedProductName = productName.replace(/\s+/g, "-").toLowerCase();
    navigate(`/products/${formattedProductName}`, { state: { productId } });
  };

  const handleChangeTab = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  console.log(
    "Tabs yang tersedia:",
    categories.map((category) => category.kategori)
  );

  return (
    <div>
      <AppBar
        position="sticky"
        elevation={0}
        sx={{
          backgroundColor: theme.palette.background.appBar,
          color: "black",
        }}
      >
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1, color: "black" }}>
            Data Produk
          </Typography>
          <TextField
            variant="outlined"
            placeholder="Cari Produk..."
            value={searchQuery}
            onChange={handleSearchChange}
            size="small"
            sx={{
              mr: 2,
              width: "270px", // Menyesuaikan panjang field di sini
              // Untuk responsive, bisa menggunakan width: '100%', maxWidth: '250px'
              "& fieldset": {
                paddingLeft: (theme) => theme.spacing(2.5),
                borderRadius: "30px",
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon sx={{ transition: "transform 0.3s" }} />}
            onClick={() => navigate("/products/add-product")}
            sx={{ "&:hover": { "& svg": { transform: "rotate(45deg)" } } }}
          >
            Tambah Produk
          </Button>
        </Toolbar>
      </AppBar>

      <Tabs
        value={activeTab}
        onChange={handleChangeTab}
        sx={{ backgroundColor: theme.palette.background.paper, padding: 3 }}
      >
        <Tab
          label="Semua Produk"
          sx={{
            color: activeTab === 0 ? "inherit" : "black",
            textTransform: "none",
          }}
        />
        {categories.map((category, index) => (
          <Tab
            key={index}
            label={category.kategori}
            sx={{
              color: activeTab === index + 1 ? "inherit" : "black",
              textTransform: "none",
            }}
          />
        ))}
      </Tabs>

      <Grid container spacing={3} sx={{ padding: 3 }}>
        {products
          .filter(
            (product) =>
              (activeTab === 0 ||
                product.kategori === categories[activeTab - 1].kategori) &&
              product.nama.toLowerCase().includes(searchQuery.toLowerCase())
          )
          .map((product) => (
            <Grid
              item
              key={product.id_produk}
              xs={12}
              sm={6}
              md={5}
              lg={3}
              xl={2}
            >
              {console.log("Kategori:", product.kategori)}
              {console.log("Subkategori:", product.subkategori)}
              {console.log("Logo Vendor:", product.logo_vendor)}

              <ProductCard
                name={product.nama}
                category={product.kategori}
                subcategory={product.subkategori}
                vendorLogo={product.logo_vendor}
                price={product.harga_jual}
                imageUrl={product.foto_produk}
                onClick={() => handleCardClick(product.id_produk, product.nama)}
              />
            </Grid>
          ))}
      </Grid>
    </div>
  );
};

export default ProductList;
