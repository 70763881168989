import React, { useState } from "react";
import {
  TextField,
  Button,
  Grid,
  Typography,
  Paper,
  IconButton,
  AppBar,
  Toolbar,
  CssBaseline,
  Modal,
  Box,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import theme from "../../../theme";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import SendIcon from "@mui/icons-material/Send";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 5,
};

const AddFabric = () => {
  const [namaKain, setNamaKain] = useState("");
  const [variasiWarnaFields, setVariasiWarnaFields] = useState([""]);

  const navigate = useNavigate();

  const [modalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const handleOpenModal = (message) => {
    setModalMessage(message);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    navigate("/products/fabric"); // Arahkan pengguna ke '/products/vendor' saat modal ditutup
  };

  const navigateToAdd = () => {
    navigate("/products/add-fabric");
  };

  const navigateToPage = () => {
    navigate("/products/fabric");
  };

  const handleBack = () => {
    // Implementasi navigasi kembali
    navigate(-1); // Untuk kembali ke halaman sebelumnya
  };

  const handleAddFabric = async () => {
    try {
      // Kirim data ke server
      const response = await fetch("https://server.ajeg.co.id/api/fabrics", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          nama_kain: namaKain,
          variasi_warna: variasiWarnaFields,
        }),
      });

      if (response.ok) {
        handleOpenModal("Data kain dan variasi warna berhasil ditambahkan!");
        // Reset state setelah pengiriman
        setNamaKain("");
        setVariasiWarnaFields([""]);
      } else {
        handleOpenModal("Gagal menambahkan data kain dan variasi warna.");
      }
    } catch (error) {
      handleOpenModal("Terjadi kesalahan:", error);
    }
  };

  const handleVariasiWarnaChange = (index, value) => {
    const updatedVariasiWarnaFields = [...variasiWarnaFields];
    updatedVariasiWarnaFields[index] = value;
    setVariasiWarnaFields(updatedVariasiWarnaFields);
  };

  const handleAddVariasiWarnaField = () => {
    setVariasiWarnaFields([...variasiWarnaFields, ""]);
  };

  const handleRemoveVariasiWarnaField = (index) => {
    const updatedVariasiWarnaFields = [...variasiWarnaFields];
    updatedVariasiWarnaFields.splice(index, 1);
    setVariasiWarnaFields(updatedVariasiWarnaFields);
  };

  return (
    <CssBaseline>
      <AppBar
        position="sticky"
        elevation={0}
        sx={{
          backgroundColor: theme.palette.background.appBar,
          color: "black",
          marginBottom: 2,
        }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="back"
            onClick={handleBack}
            sx={{
              margin: 1,
            }}
          >
            <KeyboardBackspaceIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, color: "black" }}
          >
            Tambah Kain & Warna
          </Typography>
          <Button
            sx={{
              paddingLeft: 2,
              paddingRight: 2,
              backgroundColor: theme.palette.primary.main,
              color: "white",
              "&:hover": {
                backgroundColor: theme.palette.primary.dark,
                "& svg": {
                  transform: "rotate(-45deg)",
                },
              },
              textTransform: "none",
              fontWeight: 500,
            }}
            startIcon={<SendIcon sx={{ transition: "transform 0.3s" }} />}
            onClick={handleAddFabric}
          >
            Simpan Kain & Warna
          </Button>
        </Toolbar>
      </AppBar>
      <Grid container justifyContent="center" style={{ height: "100vh" }}>
        <Grid item xs={6}>
          <Paper elevation={0} style={{ padding: "20px", textAlign: "center" }}>
            <TextField
              label="Nama Kain"
              fullWidth
              variant="outlined"
              margin="normal"
              value={namaKain}
              onChange={(e) => setNamaKain(e.target.value)}
              helperText="Harus dalam format Capitalize contoh 'Tomford' huruf T besar"
            />

            {variasiWarnaFields.map((variasiWarna, index) => (
              <div
                key={index}
                style={{ display: "flex", alignItems: "center" }}
              >
                <TextField
                  label={`Variasi Warna ${index + 1}`}
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  value={variasiWarna}
                  onChange={(e) =>
                    handleVariasiWarnaChange(index, e.target.value)
                  }
                  helperText="Harus dalam format Capitalize contoh 'Dark Grey' huruf D dan G besar"
                />
                {index === variasiWarnaFields.length - 1 && (
                  <IconButton
                    onClick={() => handleRemoveVariasiWarnaField(index)}
                    style={{ marginLeft: "10px" }}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </div>
            ))}
            <Button
              fullWidth
              variant="outlined"
              color="primary"
              onClick={handleAddVariasiWarnaField}
              style={{ marginTop: "10px" }}
            >
              <AddIcon />
              Tambah Variasi Warna
            </Button>
          </Paper>
        </Grid>
      </Grid>

      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-title" variant="h6" component="h2">
            Notifikasi
          </Typography>
          <Typography id="modal-description" sx={{ mt: 2 }}>
            {modalMessage}
          </Typography>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12}>
              <Button fullWidth variant="outlined" onClick={navigateToAdd}>
                Tambahkan Kain Baru
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button fullWidth variant="contained" onClick={navigateToPage}>
                Kembali
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </CssBaseline>
  );
};

export default AddFabric;
