import React, { useState } from "react";
import {
  TextField,
  Button,
  Paper,
  Typography,
  AppBar,
  Toolbar,
  Grid,
  Box,
  CssBaseline,
  IconButton,
  Modal,
} from "@mui/material";
import theme from "../../../theme";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import SendIcon from "@mui/icons-material/Send";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 5,
};

const Input = styled("input")({
  display: "none",
});

const AddVendor = () => {
  const [vendorData, setVendorData] = useState({
    name: "",
    phone: "",
    email: "",
    address: "",
    logo: null,
  });
  const [logoPreview, setLogoPreview] = useState(null); // State untuk menyimpan preview logo

  const [errors, setErrors] = useState({
    name: "",
    phone: "",
    email: "",
    address: "",
    logo: "",
  });
  const navigate = useNavigate();

  const [modalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const handleOpenModal = (message) => {
    setModalMessage(message);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    navigate("/products/vendor"); // Arahkan pengguna ke '/products/vendor' saat modal ditutup
  };

  const navigateToAddVendor = () => {
    navigate("/products/add-vendor");
  };

  const navigateToVendorPage = () => {
    navigate("/products/vendor");
  };

  const handleChange = (field, value) => {
    setVendorData({ ...vendorData, [field]: value });
    setErrors({ ...errors, [field]: "" });
  };

  const handleLogoChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 2 * 1024 * 1024) {
        setErrors({ ...errors, logo: "Ukuran file logo melebihi 2MB." });
        setVendorData({ ...vendorData, logo: null });
      } else {
        setLogoPreview(URL.createObjectURL(file)); // Menyimpan URL preview
        setErrors({ ...errors, logo: "" });
        setVendorData({ ...vendorData, logo: file });
      }
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePhone = (phone) => {
    const phoneRegex = /^\d{10,15}$/;
    return phoneRegex.test(phone);
  };
  const handleBack = () => {
    // Implementasi navigasi kembali
    navigate(-1); // Untuk kembali ke halaman sebelumnya
  };

  const handleAddVendor = async () => {
    if (vendorData.name === "") {
      setErrors({ ...errors, name: "Nama vendor tidak boleh kosong." });
      return;
    } else {
      setErrors({ ...errors, name: "" });
    }

    if (!validatePhone(vendorData.phone)) {
      setErrors({ ...errors, phone: "Nomor telepon tidak valid." });
      return;
    } else {
      setErrors({ ...errors, phone: "" });
    }

    if (!validateEmail(vendorData.email)) {
      setErrors({ ...errors, email: "Alamat email tidak valid." });
      return;
    } else {
      setErrors({ ...errors, email: "" });
    }

    if (vendorData.address === "") {
      setErrors({ ...errors, address: "Alamat tidak boleh kosong." });
      return;
    } else {
      setErrors({ ...errors, address: "" });
    }

    if (vendorData.logo && vendorData.logo.size > 2 * 1024 * 1024) {
      setErrors({ ...errors, logo: "Ukuran file logo melebihi 2MB." });
      return;
    } else {
      setErrors({ ...errors, logo: "" });
    }

    const formData = new FormData();
    formData.append("nama_vendor", vendorData.name);
    formData.append("telepon", vendorData.phone);
    formData.append("email", vendorData.email);
    formData.append("alamat", vendorData.address);
    formData.append("logo", vendorData.logo); // Sesuaikan nama atribut dengan parameter di upload.single()

    try {
      const response = await fetch("https://server.ajeg.co.id/api/vendors", {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        handleOpenModal("Data vendor berhasil disimpan ke database.");
        // Lakukan pengalihan atau tindakan lain yang diperlukan setelah penyimpanan berhasil
      } else {
        handleOpenModal("Gagal menyimpan data vendor ke database.");
      }
    } catch (error) {
      handleOpenModal(`Terjadi kesalahan: ${error.message}`);
    }
  };

  return (
    <CssBaseline>
      <AppBar
        position="sticky"
        elevation={0}
        sx={{
          backgroundColor: theme.palette.background.appBar,
          color: "black",
          marginBottom: 2,
        }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="back"
            onClick={handleBack}
            sx={{
              margin: 1,
            }}
          >
            <KeyboardBackspaceIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, color: "black" }}
          >
            Tambah Vendor
          </Typography>
          <Button
            sx={{
              paddingLeft: 2,
              paddingRight: 2,
              backgroundColor: theme.palette.primary.main,
              color: "white",
              "&:hover": {
                backgroundColor: theme.palette.primary.dark,
                "& svg": {
                  transform: "rotate(-45deg)",
                },
              },
              textTransform: "none",
              fontWeight: 500,
            }}
            startIcon={<SendIcon sx={{ transition: "transform 0.3s" }} />}
            onClick={handleAddVendor}
          >
            Simpan Vendor
          </Button>
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <Paper elevation={0} sx={{ width: "100%", maxWidth: 500 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Nama Vendor"
                variant="outlined"
                value={vendorData.name}
                onChange={(e) => handleChange("name", e.target.value)}
                error={!!errors.name}
                helperText={`Harus dalam format Capitalize contoh 'Kinami' huruf K besar\n${errors.name}`}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="No Telepon"
                variant="outlined"
                value={vendorData.phone}
                onChange={(e) => handleChange("phone", e.target.value)}
                error={!!errors.phone}
                helperText={`Contoh format email yang valid 081381448898\n${errors.phone}`}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Email"
                variant="outlined"
                value={vendorData.email}
                onChange={(e) => handleChange("email", e.target.value)}
                error={!!errors.email}
                helperText={
                  errors.email ||
                  "Contoh format email yang valid 'sofakinami@gmail.com'"
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Alamat"
                variant="outlined"
                value={vendorData.address}
                onChange={(e) => handleChange("address", e.target.value)}
                error={!!errors.address}
                helperText={`${
                  errors.address ? errors.address + "\n" : ""
                }Harus dalam format Capitalize contoh 'Sleman' huruf S besar`}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" gutterBottom>
                Logo (Max Size: 2MB):
              </Typography>
              <label htmlFor="contained-button-file">
                <Input
                  accept="image/*"
                  id="contained-button-file"
                  type="file"
                  onChange={handleLogoChange}
                />
                <Button variant="outlined" component="span" fullWidth>
                  {vendorData.logo ? vendorData.logo.name : "Choose File"}
                </Button>
                {logoPreview && (
                  <img
                    src={logoPreview}
                    alt="Logo Preview"
                    style={{
                      marginTop: "10px",
                      maxWidth: "100px",
                      maxHeight: "100px",
                    }}
                  />
                )}
                <Typography variant="caption" color="error" gutterBottom>
                  {errors.logo}
                </Typography>
              </label>
            </Grid>
          </Grid>
          <Modal
            open={modalOpen}
            onClose={handleCloseModal}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-title" variant="h6" component="h2">
                Notifikasi
              </Typography>
              <Typography id="modal-description" sx={{ mt: 2 }}>
                {modalMessage}
              </Typography>
              <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    variant="outlined"
                    onClick={navigateToAddVendor}
                  >
                    Tambahkan Vendor Baru
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    onClick={navigateToVendorPage}
                  >
                    Kembali
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Modal>
        </Paper>
      </Box>
    </CssBaseline>
  );
};

export default AddVendor;
