// InventoryModule.js
import React from "react";
import { Routes, Route } from "react-router-dom";
import InventoryList from "./InventoryList"; // Tampilkan daftar inventaris
import ViewStock from "./ViewStock"; // Import ViewStock

// Komponen lain yang mungkin Anda butuhkan untuk modul inventaris

const InventoryModule = () => {
  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <div style={{ flexGrow: 1 }}>
        <Routes>
          <Route path="/" element={<InventoryList />} />
          <Route path="/:productId" element={<ViewStock />} />{" "}
          {/* Rute diubah di sini */}
          {/* Tambahkan rute ini */}
          {/*
          <Route path="add-item" element={<AddInventoryItem />} />
          <Route path="edit-item/:itemId" element={<EditInventoryItem />} /> */}
          {/* Tambahkan rute lain yang diperlukan untuk modul inventaris di sini */}
          {/* Halaman default opsional */}
        </Routes>
      </div>
    </div>
  );
};

export default InventoryModule;
