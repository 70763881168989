// src/index.js

import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import { AuthProvider } from "./context/AuthContext"; // Pastikan jalur ini sesuai dengan lokasi AuthContext.js Anda

const root = createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <AuthProvider>
        {" "}
        {/* Tambahkan AuthProvider di sini */}
        <App />
      </AuthProvider>
    </ThemeProvider>
  </React.StrictMode>
);
